import { createSlice } from '@reduxjs/toolkit';

const attachmentsInitialState = {
    balancoPatrimonial: null,
    relatorioFaturamento: null,
    contratoSocial: null,
    documentosSocios: null,
    garantiaDocumentos: null,
};

const jurosAcumuladosInitialState = {
    jurosAcumulados: '',
}

const calcDataInitialState = {
    calcData: null,
}

const sacDataInitialState = {
    sacData: null,
}

const emissorInitialState = {
    emissor: null,
}

const investidorInitialState = {
    investidor: null,
}

const dadosInitialState = {
    dados: {},
};

const finalTotalInitialState = {
    finalTotal: '',
};

const htmlInitialState = {
    html: '',
};

const attachmentsSlice = createSlice({
    name: 'attachments',
    initialState: attachmentsInitialState,
    reducers: {
        setAttachment: (state, action) => {
            const { fieldName, file } = action.payload;
            state[fieldName] = file;
        },
        clearAttachment: (state, action) => {
            const { fieldName } = action.payload;
            state[fieldName] = null;
        },
    },
});

const jurosAcumuladosSlice = createSlice({
    name: 'jurosAcumulados',
    initialState: jurosAcumuladosInitialState,
    reducers: {
        setJurosAcumulados: (state, action) => {
            state.jurosAcumulados = action.payload;
        },
    },
});

const calcDataSlice = createSlice({
    name: 'calcData',
    initialState: calcDataInitialState,
    reducers: {
        setCalcData: (state, action) => {
            state.calcData = action.payload;
        },
    },
});

const sacDataSlice = createSlice({
    name: 'sacData',
    initialState: sacDataInitialState,
    reducers: {
        setSacData: (state, action) => {
            state.sacData = action.payload;
        },
    },
});

const emissorSlice = createSlice({
    name: 'emissor',
    initialState: emissorInitialState,
    reducers: {
        setEmissor: (state, action) => {
            state.emissor = action.payload;
        },
    },
});

const investidorSlice = createSlice({
    name: 'investidor',
    initialState: investidorInitialState,
    reducers: {
        setInvestidor: (state, action) => {
            state.investidor = action.payload;
        },
    },
});

const dadosSlice = createSlice({
    name: 'dados',
    initialState: dadosInitialState,
    reducers: {
        setDados: (state, action) => {
            state.dados = action.payload;
        },
    },
});

const finalTotalSlice = createSlice({
    name: 'finalTotal',
    initialState: finalTotalInitialState,
    reducers: {
        setFinalTotal: (state, action) => {
            state.finalTotal = action.payload;
        },
    },
});

const htmlSlice = createSlice({
    name: 'html',
    initialState: htmlInitialState,
    reducers: {
        setHtml: (state, action) => {
            state.html = action.payload;
        },
    },
});

export const { setAttachment, clearAttachment } = attachmentsSlice.actions;

export const { setJurosAcumulados } = jurosAcumuladosSlice.actions;
export const { setCalcData } = calcDataSlice.actions;
export const { setSacData } = sacDataSlice.actions;
export const { setEmissor } = emissorSlice.actions;
export const { setInvestidor } = investidorSlice.actions;
export const { setDados } = dadosSlice.actions;
export const { setFinalTotal } = finalTotalSlice.actions;
export const { setHtml } = htmlSlice.actions;


const rootReducer = {
    attachmentsReducer: attachmentsSlice.reducer,
    jurosAcumulados: jurosAcumuladosSlice.reducer,
    calcData: calcDataSlice.reducer,
    sacData: sacDataSlice.reducer,
    emissor: emissorSlice.reducer,
    investidor: investidorSlice.reducer,
    dados: dadosSlice.reducer,
    finalTotal: finalTotalSlice.reducer,
    html: htmlSlice.reducer,
};

export default rootReducer;
