import React, { useEffect, useRef, useState } from "react";
import Header from "../components/Header";
import '../styles/DocGen.css';
import SingularePdf1 from "../utils/pdfs/singularePdf1";
import SingulareSemGarantia from "../utils/pdfs/SingulareSemGarantia";
import SingulareComGarantia from "../utils/pdfs/SingulareComGarantia";
import VortxPdf from "../utils/pdfs/VortxPdf";
import VortxSemGarantia from "../utils/pdfs/VortxSemGarantia";
import { useSelector } from "react-redux";
import { generateCNABFile } from '../utils/CNABGenerator';
import { FaRegFileLines, FaRegFilePdf } from "react-icons/fa6";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import TrusteeSemGarantia from "../utils/pdfs/TrusteeSemGarantia";
import TrusteeComGarantia from "../utils/pdfs/TrusteeComGarantia";
import TrusteeCondicoes from "../utils/pdfs/trusteeCondicoes";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { PDFDownloadLink, PDFViewer, pdf, renderToBuffer, renderToFile } from "@react-pdf/renderer";
import PostNote from "../components/PostNotes"; // Importe o componente PostNote aqui
import FileSaver from 'file-saver';
import { css } from '@emotion/react';
import { ClipLoader } from 'react-spinners';
import authenticate from "../utils/it4";
import { apenasDigitos, apenasNumeros, convertToNumber, formatDate, parseDate, parseDateString, extractFirstNumber } from "../utils/Tools";
import notaIt4 from "../utils/it4";
import { NumericFormat } from "react-number-format";
import LaqusButton from "../components/LaqusButton";
import { notaLaqus, notaLaqusUpload, regularizaEmissor, regularizaInvestidor, escrituracaoNotaLaqus } from "../utils/laqus";
import laqusBuilder from "../utils/laqusBuilder";
import ModalPopup from "../utils/Modals/ModalPopup";
import LaqusNotes from "../components/LaqusNotes";
import LaqusSemGarantia from "../utils/pdfs/LaqusSemGarantia";
import OsloSemGarantia from "../utils/pdfs/OsloSemGarantia";
import OsloComGarantia from "../utils/pdfs/OsloComGarantia";

const DocGen = () => {
    const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
    const [available, setAvailable] = useState(false);
    const [semGarantia, setSemGarantia] = useState(false);
    const [comGarantia, setComGarantia] = useState(false);
    const [vortxAvailable, setVortxAvailable] = useState(false);
    const [modoSingulareExpandido, setModoSingulareExpandido] = useState(false);
    const [modoVortxExpandido, setModoVortxExpandido] = useState(false);
    const [vortxSemGarantia, setVortxSemGarantia] = useState(false);
    const [trusteeSemGarantia, setTrusteeSemGarantia] = useState(false);
    const [trusteeComGarantia, setTrusteeComGarantia] = useState(false);
    const [osloSemGarantia, setOsloSemGarantia] = useState(false);
    const [osloComGarantia, setOsloComGarantia] = useState(false);
    const [modoCnabExpandido, setModoCnabExpandido] = useState(false);
    const [trusteeAvailable, setTrusteeAvailable] = useState(false);
    const [semGarantiaLaqus, setSemGarantiaLaqus] = useState(false);
    const [emissao, setEmissao] = useState('1ª (PRIMEIRA)');
    const [indexador, setIndexador] = useState('Pré-Fixado');
    const [sendingNote, setSendingNote] = useState(false); // Estado para controlar o envio da nota
    const [prcntgm, setPrcntgm] = useState('');
    const [generatedPDF, setGeneratedPDF] = useState(null);
    const [fourDigit, setFourDigit] = useState(['', '', '', '']);
    const [controleParticipante, setControleParticipante] = useState('');
    const [enviandoEscri, setEnviandoEscri] = useState(false);
    const [codigoId, setCodigoId] = useState('');
    const [nomeOriginador, setNomeOriginador] = useState('');
    const [cnpjOriginador, setCnpjOriginador] = useState('');
    const inputRefs = useRef([null, null, null, null]);
    const [pdfBlob, setPdfBlob] = useState(null);
    const [qualFoi, setQualFoi] = useState('');
    const navigate = useNavigate();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [laqusNote, setLaqusNote] = useState(false); // Estado para controlar o envio da nota
    const [laqusNoteIdEscrituracao, setLaqusNoteIdEscrituracao] = useState(null); // Estado para controlar o envio da nota
    const [pendeciasLaqus, setPendeciasLaqus] = useState({
        'investidor':[],
        'investidorLaqus':"",
        'emissor':[],
        'emissorLaqus':"",
        'emissaoLaqus':[],
        'emissaoLaqusFail': false,
    });
    const resetPendeciasLaqus = () => setPendeciasLaqus(
        {
        'investidor':[],
        'investidorLaqus':'' ,
        'emissor':[],
        'emissorLaqus':'',
        'emissaoLaqus':[],
        'emissaoLaqusFail': false,
        }
    )
    const atualizarPendenciasLaqus = (_pendencias) =>{
        setPendeciasLaqus({
            ...pendeciasLaqus,
            ..._pendencias
        })
    }
    const hasPendeciasLaqus = () => {
        const {investidor, investidorLaqus, emissor, emissorLaqus} = pendeciasLaqus;
        return investidor.length || investidorLaqus.length || emissor.length || emissorLaqus.length;
    }
    const generateBlobFromComponent = async (component) => {

        const pdfString = await TrusteeSemGarantia.toPdfString(component);
        return new Blob([pdfString], { type: 'application/pdf' });
    };
    const indexadoresEnvioDefault = [
        {key: 'Pré-Fixado', value: 'Pré-Fixado'},
        {key: 'IPCA', value: 'IPCA'},
        {key: 'CDI', value: 'CDI'},
        {key: 'TR', value: 'TR'},
        {key: 'IGPM', value: 'IGPM'},
    ];
    const indexadoresNotaLaqusDefault = [
        {key: 'Pre', value: 'Pré'},
        {key: 'CDI', value: 'CDI'},
        {key: 'SELIC', value: 'SELIC'},
        {key: 'IPCA', value: 'IPCA'},
    ];
    const [indexadores, setIndexadores] = useState(indexadoresEnvioDefault);
    const alternarIndexadores = ({enviaNota=false,notaLaqus=false})=>{
        if(enviaNota){
            setIndexador("Pré-Fixado");
            setIndexadores(indexadoresEnvioDefault);
        }
        if(notaLaqus){
            setIndexador("Pre");
            setIndexadores(indexadoresNotaLaqusDefault);
        }
    }
    const [ipcaCase, setIpcaCase] = useState({
        proRata:"Corrido", /* Corrido | Util */
        tipoProjecao:"Anbima",/* Anbima | RepeteUltimo */
        diaAniversario:1, /* 1 -28 */
    });

    const handleChangeDiaAniversario = (event) => {
        const value = (event?.target?.value)??1;
        if(value<=28 && value>0){
            setIpcaCase({
                ...ipcaCase,
                diaAniversario:value
            });
        }
    };
    
    const numerosOrdinais = [
        "1ª (PRIMEIRA)",
        "2ª (SEGUNDA)",
        "3ª (TERCEIRA)",
        "4ª (QUARTA)",
        "5ª (QUINTA)",
        "6ª (SEXTA)",
        "7ª (SÉTIMA)",
        "8ª (OITAVA)",
        "9ª (NONA)",
        "10ª (DÉCIMA)",
        "11ª (DÉCIMA PRIMEIRA)",
        "12ª (DÉCIMA SEGUNDA)",
        "13ª (DÉCIMA TERCEIRA)",
        "14ª (DÉCIMA QUARTA)",
        "15ª (DÉCIMA QUINTA)",
        "16ª (DÉCIMA SEXTA)",
        "17ª (DÉCIMA SÉTIMA)",
        "18ª (DÉCIMA OITAVA)",
        "19ª (DÉCIMA NONA)",
        "20ª (VIGÉSIMA)",
        "21ª (VIGÉSIMA PRIMEIRA)",
        "22ª (VIGÉSIMA SEGUNDA)",
        "23ª (VIGÉSIMA TERCEIRA)",
        "24ª (VIGÉSIMA QUARTA)",
        "25ª (VIGÉSIMA QUINTA)",
        "26ª (VIGÉSIMA SEXTA)",
        "27ª (VIGÉSIMA SÉTIMA)",
        "28ª (VIGÉSIMA OITAVA)",
        "29ª (VIGÉSIMA NONA)",
        "30ª (TRIGÉSIMA)",
        "31ª (TRIGÉSIMA PRIMEIRA)",
        "32ª (TRIGÉSIMA SEGUNDA)",
        "33ª (TRIGÉSIMA TERCEIRA)",
        "34ª (TRIGÉSIMA QUARTA)",
        "35ª (TRIGÉSIMA QUINTA)",
        "36ª (TRIGÉSIMA SEXTA)",
        "37ª (TRIGÉSIMA SÉTIMA)",
        "38ª (TRIGÉSIMA OITAVA)",
        "39ª (TRIGÉSIMA NONA)",
        "40ª (QUADRAGÉSIMA)",
        "41ª (QUADRAGÉSIMA PRIMEIRA)",
        "42ª (QUADRAGÉSIMA SEGUNDA)",
        "43ª (QUADRAGÉSIMA TERCEIRA)",
        "44ª (QUADRAGÉSIMA QUARTA)",
        "45ª (QUADRAGÉSIMA QUINTA)",
        "46ª (QUADRAGÉSIMA SEXTA)",
        "47ª (QUADRAGÉSIMA SÉTIMA)",
        "48ª (QUADRAGÉSIMA OITAVA)",
        "49ª (QUADRAGÉSIMA NONA)",
        "50ª (QUINQUAGÉSIMA)"
      ];
    
    const emissorPreenchido = JSON.parse(localStorage.getItem('emissorPreenchido'));
    const investidorPreenchido = JSON.parse(localStorage.getItem('investidorPreenchido'));
    const formValues = JSON.parse(localStorage.getItem('formValues'));
    const allInfoArray = useSelector((state) => state.finalTotal.finalTotal);
    const emissor = JSON.parse(localStorage.getItem('emissor'));
    const investidor = JSON.parse(localStorage.getItem('investidor'));
    const garantiaDataItem = localStorage.getItem('garantiaData');
    let garantiaData;

    if (garantiaDataItem) {
        try {
            garantiaData = JSON.parse(garantiaDataItem);
        } catch (e) {
            console.error('Erro ao analisar os dados da garantia:', e);
            // Trate o erro conforme necessário, talvez definindo garantiaData como null ou um objeto vazio
        }
    } else {
        console.warn('Nenhum dado de garantia encontrado no localStorage.');
        // Trate a ausência de dados conforme necessário
    }

    const user = JSON.parse(localStorage.getItem('user'));
    const calcData = JSON.parse(localStorage.getItem('calcData'));
    const sacData = useSelector((state) => state.sacData.sacData);
    const [showErrorModal, setShowErrorModal] = useState(false);

    const toggleModoSingulare = () => {
        setModoSingulareExpandido(!modoSingulareExpandido);
    };

    const toggleModoCnab = () => {
        setModoCnabExpandido(!modoCnabExpandido);
    };

    const toggleModoVortx = () => {
        setModoVortxExpandido(!modoVortxExpandido);
    };
    const validateFields = (data, objName) => {
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const value = data[key];
                // Verifique se o valor é nulo, uma string vazia ou contém apenas espaços
                if (value === null || (typeof value === 'string' && value.trim() === '')) {
                    // Verifique se a chave é uma das exceções
                    if (
                        key !== 'complemento' &&
                        key !== 'garantia' &&
                        key !== 'gTipo' &&
                        key !== 'gNatureza' &&
                        key !== 'gDescricao' &&
                        key !== 'gDocumento' &&
                        key !== 'gProprietario' &&
                        key !== 'gQuantidade' &&
                        key !== 'nome2'
                    ) {
                        window.alert(`O campo "${key}" em ${objName} não pode estar vazio.`);
                        return false;
                    }
                }
            }
        }
        return true;
    };


    useEffect(() => {
        if (!emissor || !investidor || !user || !calcData || !sacData) {
            console.error("Alguma variável essencial é undefined ou nula");
            setTimeout(() => {
                window.alert('Cálculo não realizado!');
                navigate('/home');
            }, 1)
        } else if (!emissor?.razaoSocial) {
            window.alert('Emissor deve ser preenchido');
            navigate('/emissor');
        }
        window.scrollTo(0, 0);
    }, [emissor, investidor, user, calcData, sacData, navigate]);

    useEffect(() => {
        if (emissor?.razaoSocial === null) {
            window.alert('Emissor deve ser preenchido');
            navigate('/emissor');
        }
        window.scrollTo(0, 0);
    }, []);

    const dados = {
        emissao,
        indexador,
        prcntgm
    }
    const garantia = JSON.parse(localStorage.getItem('garantia'));


    const handleCnab = () => {
        console.log('ta chamando');
        let newDigits = fourDigit.join('')
        console.log(newDigits)
        if (allInfoArray[4] > 0) {
            const infoArray = {
                nomeOriginador: investidor?.razaoSocial, //0
                bancoOrigNumber: investidor?.numeroDoBanco, //1
                bancoOrig: investidor?.banco, //2
                conta: investidor?.conta, //3
                agency: investidor?.agencia, //4
                idTitulo: controleParticipante, //5
                idDocumento: newDigits, //6
                pessoa: 'Juridica', //7
                nTC: '', //8
                cnpjSacado: emissor?.cnpj, //9
                endereco: emissor?.rua, //10
                numeroNotaFiscal: '',//11
                formattedCep: emissor?.cep, //12
                formattedCnpj: emissor?.cnpj, //13
                bankNumber: emissor?.bankNumber,//14
                selectedBank: emissor?.banco, //15
                //idTitulo, 16
                razaoSacado: emissor?.razaoSocial, //17
                enderecoSacado: emissor?.rua, //18
                formattedCepSacado: emissor?.cep, //19
                razaoCedente: 'SINGULARE', //20
                newDigits: newDigits, //21
                codigoId, //22
                cnpjOriginador
            };
            console.log(infoArray);
            generateCNABFile(infoArray, allInfoArray);
        }
    };
    const handleAvailable = (type) => {
        if (!emissor) {
            window.alert('O formulário do Emissor não foi preenchido!');
            return;
        }

        if (!investidor) {
            window.alert('O formulário do Investidor não foi preenchido!');
            return;
        }

        if (!validateFields(emissor, "emissor") || !validateFields(investidor, "investidor")) {
            return;
        }
        if (allInfoArray.length < 1) {
            window.alert('Cálculo não realizado!');
            return;
        }
        if (type === 'semGarantia') {
            setSemGarantia(!semGarantia); // Alterna entre verdadeiro e falso
            setAvailable(false); // Certifica-se de fechar a outra seção
            setComGarantia(false);
            setTrusteeSemGarantia(false)
            setVortxAvailable(false);
            setTrusteeComGarantia(false)
            setVortxSemGarantia(false);

        } else if (type === 'available') {
            setComGarantia(false)
            setSemGarantia(false); // Certifica-se de fechar a outra seção
            setAvailable(!available); // Alterna entre verdadeiro e falso
            setTrusteeAvailable(false)
            setVortxAvailable(false);
            setTrusteeSemGarantia(false)
            setVortxSemGarantia(false);
            setTrusteeComGarantia(false)
            setSemGarantiaLaqus(false)

        } else if (type === 'comGarantia') {
            setComGarantia(!comGarantia); // Alterna entre verdadeiro e falso
            setSemGarantia(false); // Certifica-se de fechar a outra seção
            setAvailable(false); // Alterna entre verdadeiro e falso
            setVortxAvailable(false);
            setVortxSemGarantia(false);
            setTrusteeComGarantia(false)
            setTrusteeAvailable(false)
            setTrusteeSemGarantia(false)
            setSemGarantiaLaqus(false)

        } else if (type === 'vortxAvailable') {
            setComGarantia(false)
            setSemGarantia(false);
            setAvailable(false);
            setVortxAvailable(!vortxAvailable);
            setVortxSemGarantia(false);
            setTrusteeSemGarantia(false)
            setTrusteeAvailable(false)
            setTrusteeComGarantia(false)
            setSemGarantiaLaqus(false)

        } else if (type === 'vortxSemGarantia') {
            setComGarantia(false)
            setSemGarantia(false);
            setAvailable(false);
            setVortxAvailable(false);
            setTrusteeAvailable(false)
            setVortxSemGarantia(!vortxSemGarantia);
            setTrusteeSemGarantia(false)
            setTrusteeComGarantia(false)
            setSemGarantiaLaqus(false)

        } else if (type === 'trusteeSemGarantia') {
            setComGarantia(false)
            setSemGarantia(false);
            setAvailable(false);
            setVortxAvailable(false);
            setVortxSemGarantia(false);
            setTrusteeAvailable(false);
            setSemGarantiaLaqus(false);
            if (garantia === false) {
                setTrusteeComGarantia(false);
                setTrusteeSemGarantia(true);
                setQualFoi('trusteeSemGarantia')
                handleAnexo()
            } else {
                setTrusteeComGarantia(true);
                setTrusteeSemGarantia(false);
                setQualFoi('trusteeComGarantia')
            }
        } else if (type === 'trusteeComGarantia') {
            setComGarantia(false)
            setSemGarantia(false);
            setAvailable(false);
            setVortxAvailable(false);
            setVortxSemGarantia(false);
            setTrusteeSemGarantia(false);
            setTrusteeComGarantia(!trusteeComGarantia);
            setTrusteeAvailable(false);
            setSemGarantiaLaqus(false);
        } else if (type === 'trusteeAvailable') {
            setComGarantia(false)
            setSemGarantia(false);
            setAvailable(false);
            setVortxAvailable(false);
            setVortxSemGarantia(false);
            setTrusteeSemGarantia(false);
            setTrusteeComGarantia(false);
            setTrusteeAvailable(!trusteeAvailable);
            setSemGarantiaLaqus(false);
            } else if (type === 'oslo') {
            setComGarantia(false)
            setSemGarantia(false);
            setAvailable(false);
            setVortxAvailable(false);
            setVortxSemGarantia(false);
            setTrusteeAvailable(false)
            setTrusteeComGarantia(false);
            setTrusteeSemGarantia(false);
            if (garantia === false) {
                setOsloSemGarantia(true);
                setOsloComGarantia(false);
            } else {
                setOsloSemGarantia(false);
                setOsloComGarantia(true);
            }
        }else if(type ==="semGarantiaLaqus"){
            setComGarantia(false)
            setSemGarantia(false);
            setAvailable(false);
            setVortxAvailable(false);
            setVortxSemGarantia(false);
            setTrusteeSemGarantia(false);
            setTrusteeComGarantia(false);
            setTrusteeAvailable(false);
            setSemGarantiaLaqus(!semGarantiaLaqus);
        }
    };
    const now = new Date();
    let dataArray = [];
    if (allInfoArray && allInfoArray[7]) {
        dataArray = allInfoArray[7].split('/');
    } else {
        console.error("allInfoArray[7] is undefined");
        return null;
    }

    let vencimentoArray = [];
    if (formValues && formValues.vencimento) {
        vencimentoArray = formValues.vencimento.split('/');
    } else {
        console.error("formValues.vencimento is undefined");
    }


    const linhaDoVencimento = calcData && calcData.length > 0
        ? calcData[calcData.length - 1]
        : sacData && sacData.length > 0
            ? sacData[sacData.length - 1]
            : null;

    if (!linhaDoVencimento) {
        console.error("linhaDoVencimento is undefined");
        return null;
    }


    let dataDoVencimento;
    if (linhaDoVencimento[1] && linhaDoVencimento[2] && linhaDoVencimento[3]) {
        dataDoVencimento = `${linhaDoVencimento[1] < 10 ? `0${linhaDoVencimento[1]}` : linhaDoVencimento[1]
            }/${linhaDoVencimento[2]}/${linhaDoVencimento[3]}`;
    } else {
        console.error("Data do vencimento está incompleta");
        return null;
    }
    const dataEmissao = formatDate(now);
    const data1 = parseDateString(dataEmissao);
    const data2 = parseDate(dataDoVencimento);
    const diffEmMilissegundos = data2 - data1;
    const umDiaEmMilissegundos = 1000 * 60 * 60 * 24;
    const totalDias = Math.floor(diffEmMilissegundos / umDiaEmMilissegundos);

    let primeiroNomeAbreviado;
    if (emissor?.razaoSocial) {
        primeiroNomeAbreviado = emissor?.razaoSocial.split(' ')[0];
    } else {
        console.error("emissor.razaoSocial is undefined");
        return null;
    }

    const formatDateIt4 = (times) => {
        const date = new Date(times);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${day}`;
    };

    // Exemplo de uso:
    //console.log(formatDateIt4(now), 'formatdateIt4'); // Formata a data de hoje

    // Você também pode passar outras datas:

    const dataIt4 = {
        Cnpj: apenasNumeros(emissor.cnpj),
        Nome: emissor.razaoSocial,
        NomeAbreviado: primeiroNomeAbreviado,
        Isin: "BRBR6Q769LYK18",
        DataInicialCustodia: formatDateIt4(now),
        DataAtualCustodia: formatDateIt4(now),
        DataPrevistaVencimento: formatDateIt4(dataDoVencimento) || "2025-12-14",
        SubTipo: 192,
        Modalidade: 'null',
        Contrato: "85601",
        DescricaoGarantia: garantia ? garantiaData.gDescricao : "null",
        Dados: {
            QuantidadeEmitida: '1',
            PUEmissao: 1,
            ValorEmissao: convertToNumber(allInfoArray[5]),
            DataVencimento: formatDateIt4(dataDoVencimento),
            DataInicioRentabilidade: formatDateIt4(dataDoVencimento),
            TipoPagJuros: "bullet",
            TipoPagAmortizacao: "anual",
            CodSerieIndexador: 188,
            PercRemuneracao: 0,
            TaxaPreMes: 0,
            NumeroDiasTaxa: formValues?.dias == 30 ? 360 : 252,
            IndicesInflacao: null
        },
        Emissor: {
            Cnpj: apenasNumeros(emissor.cnpj),
            Dados: {
                Nome: emissor.razaoSocial,
                Isin: "BR0LK7I39050",
                Email: emissor.email,
                CVM: false,
                Endereco: {
                    Cep: apenasNumeros(emissor.cep),
                    Endereco: emissor.rua,
                    EnderecoNumero: emissor.numero,
                    EnderecoComplemento: emissor.complemento,
                    Bairro: emissor.bairro,
                    Cidade: emissor.cidade,
                    UF: emissor.estado
                }
            }
        },
        Administrador: {
            Cnpj: 51977990000153,
            Dados: {
                PathLogotipoAdministrador: "/sbin/architect.hh",
                PathImagemTextoCarta: "/Network/static_online_implement.install",
                PathAssinaturaCarta: "/private/tmp/kids.psf",
                RelPdfSenhaMaster: "USL51K4On2",
                RelPdfPrefixoSenhaCliente: "69clWUDbXM",
                RespDirfNome: "Caio Braga",
                RespDirfCpf: "764.768.985-15",
                RespDirfEmail: "CaioBraga_Carvalho@live.com",
                RespDirfDDD: "57",
                RespDirfTelefone: "63155843",
                RespDirfRamal: null,
                RespDirfFax: "92298004",
                DirfNatureza: "5",
                DirfCpfResponsavelCnpj: "764.768.985-15",
                DirfRegimeFII: "1",
                DirfIndicadorSocioOstensivo: true,
                DirfIndicadorDeclaranteDepositario: true,
                DirfIndicadorDeclaranteInstituicao: true,
                DirfIndicadorDeclaranteRendPagExterior: true,
                DirfIndicadorPlanoPrivado: true,
                DirfIndicadorPagOlimpiadas: true,
                DirfIndicadorUniaoDetemMaioria: true,
                DirfIndicadorSituacaoEspecial: false,
                Nome: "Silva e Associados",
                Isin: "BR54R0X5B006",
                Email: "SilvaeAssociados19@gmail.com",
                CVM: null,
                Endereco: {
                    Cep: 22192561,
                    Endereco: "Maria Alice Avenida",
                    EnderecoNumero: "787",
                    EnderecoComplemento: "1482176349",
                    Bairro: "Fabiano Rua",
                    Cidade: "São Bernardo do Campo",
                    UF: "MA"
                }
            }
        },
        custodiante: {
            Cnpj: apenasNumeros(investidor.cnpj),
            Dados: {
                Nome: investidor.razaoSocial,
                Isin: "BREIYAM39130",
                Email: investidor.representantes[0].email,
                CVM: false,
                Endereco: {
                    Cep: apenasNumeros(investidor.cep),
                    Endereco: investidor.rua,
                    EnderecoNumero: investidor.numero,
                    EnderecoComplemento: investidor.complemento,
                    Bairro: investidor.bairro,
                    Cidade: investidor.cidade,
                    UF: investidor.estado,
                }
            }
        },
        liquidante: {
            Cnpj: 51977990000153,
            Dados: {
                Nome: "Reis EIRELI",
                Isin: "BR8Z7Y39KF11",
                Email: "ReisEIRELI82@hotmail.com",
                CVM: true,
                Endereco: {
                    Cep: 12742045,
                    Endereco: "Murilo Alameda",
                    EnderecoNumero: "6543",
                    EnderecoComplemento: "1748099278",
                    Bairro: "Margarida Avenida",
                    Cidade: "Limeira",
                    UF: "TO"
                }
            }
        },
        Investidores: [62725784824],
        AgendaPagamentos: [
            {
                Data: "2026-08-19",
                HasJuros: true,
                HasAmortizacao: false,
                ValorPrevisto: 486.40
            },
            {
                Data: "2023-12-10",
                HasJuros: true,
                HasAmortizacao: true,
                ValorPrevisto: 165.73
            },
            {
                Data: "2026-07-25",
                HasJuros: true,
                HasAmortizacao: false,
                ValorPrevisto: 715.24
            },
            {
                Data: "2026-05-15",
                HasJuros: true,
                HasAmortizacao: false,
                ValorPrevisto: 750.36
            },
            {
                Data: "2025-11-15",
                HasJuros: true,
                HasAmortizacao: true,
                ValorPrevisto: 712.28
            },
            {
                Data: "2026-07-11",
                HasJuros: true,
                HasAmortizacao: false,
                ValorPrevisto: 768.06
            },
            {
                Data: "2024-10-07",
                HasJuros: true,
                HasAmortizacao: false,
                ValorPrevisto: 335.16
            },
            {
                Data: "2026-06-25",
                HasJuros: true,
                HasAmortizacao: false,
                ValorPrevisto: 589.96
            }
        ]

    }
    const handleEscri = async () => {
        setEnviandoEscri(true);
        notaIt4({dataIt4});
        setTimeout(() => {
            setEnviandoEscri(false);

        }, 3000);
    };

    const handleCadastrosLaqus = async () => {
        resetPendeciasLaqus();
        setEnviandoEscri(true);
        setModalIsOpen(false);
        alternarIndexadores({notaLaqus:true})
            const emissorUserRef = {
                userRef: user.data.email,
                emissor: emissor,
                emissorCnpj: emissor.cnpj,
                emissorId: emissor._id
            }

            const promiseEmissor = regularizaEmissor(emissorUserRef).then((value)=>({
                status: value.status,
                value: value,
                id:"emissor"
            })).catch(reason => ({
                status: 'rejected',
                reason: reason,
                id:"emissor"
            }));

            const investidorUserRef = {
                userRef: user.data.email,
                investidor: investidor,
                investidorCnpj: investidor.cnpj,
                investidorId: investidor._id
            }
            const promiseInvestidor = regularizaInvestidor(investidorUserRef).then((value)=>({
                status: value.status,
                value: value,
                id:"investidor"
            })).catch(reason => ({
                status: 'rejected',
                reason: reason,
                id:"investidor"
            }));;

        const promisesEmissorInvestidor =  await Promise.allSettled([
            promiseEmissor, 
            promiseInvestidor
        ]);

           const promisesRejected = promisesEmissorInvestidor.map((promise)=> promise.value).filter((promise)=>{
            const {id, reason, status} = promise;
                if(status == "rejected"){
                    const {code, response} = reason;
                    if(id == 'investidor' && code == "ERR_BAD_REQUEST"){
                        atualizarPendenciasLaqus({
                            investidor:response?.data?.errors??[],
                            investidorLaqus: response?.data?.messageLaqus??"",
                        })
                    }
                    if(id == 'emissor' && code == "ERR_BAD_REQUEST"){
                        atualizarPendenciasLaqus({
                            emissor:response?.data?.errors??[],
                            emissorLaqus: response?.data?.messageLaqus??"",
                        })
                    }
                    return promise;
                }
            })

            if(hasPendeciasLaqus() || promisesRejected?.length){
                setModalIsOpen(true);
            }else{
                setSendingNote(false);
                setLaqusNote(true);
            }   
            console.log({promisesRejected});
            console.log({promisesEmissorInvestidor});
        setEnviandoEscri(false);
    };
    const closeFileListLaqus = async ()=>{
        setLaqusNote(false);
        alternarIndexadores({enviaNota:true})
    }
    const handleEmissaoLaqus = async ({files, onSuccess}) =>{
        setEnviandoEscri(true);
        const numeroDaEmissao = extractFirstNumber(emissao);

        let laqusData = laqusBuilder({
            formValues,
            linhaDoVencimento,
            calcData,
            investidor,
            allInfoArray,
            indexador,
            prcntgm,
            emissor,
            ipcaCase,
            numeroDaEmissao
        })
        let laqusId = null;
        const userRef = user.data.email;
        try {
            console.log(laqusData);    
            const dataDoVencimento = linhaDoVencimento[1] < 10 ? `0${linhaDoVencimento[1]}/${linhaDoVencimento[2]}/${linhaDoVencimento[3]}` : `${linhaDoVencimento[1]}/${linhaDoVencimento[2]}/${linhaDoVencimento[3]}`;
            if(laqusNoteIdEscrituracao===null){
                const {data:notaLagusEscritu} = await notaLaqus({
                    nota:{
                        userRef,
                        emitted:{
                            userRef:user.data.cnpj,
                            parcelas:formValues.parcelas,
                            valorTotal:formValues.valorTotal,
                            valorOperacao: allInfoArray[5],
                            vencimento: dataDoVencimento,
                            razaoEmissor: emissor.razaoSocial,
                        },
                        ...laqusData
                    }
                });
                laqusId = notaLagusEscritu?.notaComercial?.id??null;
                setLaqusNoteIdEscrituracao(laqusId);
                console.log({"notaLagusEscritu?.notaComercial":notaLagusEscritu?.notaComercial});
                console.log({"notaLagusEscritu?.notaComercial?.id":notaLagusEscritu?.notaComercial?.id});
                console.log({"laqusId":laqusId});
            }


        } catch (error) {
            console.log("error", error);
            const {code, response} = error;
            if(code == "ERR_BAD_REQUEST"){
                atualizarPendenciasLaqus({
                    emissaoLaqus:response?.data?.messageLaqus??[],
                    emissaoLaqusFail: true,
                })
            }else{
                alert("Falha no envio para escrituração.")
            }
        }
        try{
            if(laqusId && laqusNoteIdEscrituracao===null){
                const notaLaqusUploadResult = await notaLaqusUpload({
                   userRef,
                   laqusId,
                   files
                });
                console.log({notaLaqusUploadResult})
            }
        } catch (error) {
            console.log(error);
            const {code, response} = error;
            if(code == "ERR_BAD_REQUEST"){
                atualizarPendenciasLaqus({
                    emissaoLaqus:response?.data?.messageLaqus??[],
                    emissaoLaqusFail: true,
                })
            }else{
                alert("Falha no envio dos documentos.")
            }
            
        }

        try{
            laqusId = laqusId??laqusNoteIdEscrituracao;
            if(laqusId){
                const notaLaqusUploadEscritu = await escrituracaoNotaLaqus({
                   userRef,
                   laqusId,
                });
                console.log({notaLaqusUploadEscritu})
                setLaqusNoteIdEscrituracao(null);
                laqusId = null;
                alert("Solicitação realizada com sucesso.");
                onSuccess();
                navigate("/emitted")
            }
        } catch (error) {
            console.log(error);
            const {code, response} = error;
            if(code == "ERR_BAD_REQUEST"){
                atualizarPendenciasLaqus({
                    emissaoLaqus:response?.data?.messageLaqus??[],
                    emissaoLaqusFail: true,
                })
            }else{
                alert("Falha no escrituração.")
            }
            
        } finally{
            setTimeout(() => {
                setEnviandoEscri(false);
            }, 3000);
        }

    };

    const handleFourDigit = (index, newValue) => {
        const newValues = [...fourDigit];
        newValues[index] = newValue;
        setFourDigit(newValues);
        if (newValue && index < 3) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (index, e) => {
        if (e.key === 'Backspace' && index > 0 && !fourDigit[index]) {
            inputRefs.current[index - 1].focus();
        }
    };

    const handlePrcntg = (e) => {
        let inputValue = e.target.value.replace(/[^0-9.%]/g, '');

        // Remove múltiplos % e garante que há no máximo duas casas decimais após o ponto
        inputValue = inputValue
            .replace(/%/g, '')
            .replace(/\.(?=.*\.)/g, '')
            .replace(/(\.\d{2})\d+$/g, '$1');

        setPrcntgm(inputValue);
    };

    const handleBlur = () => {
        const floatValue = parseFloat(prcntgm.replace(/%/g, ''));
        if (!isNaN(floatValue)) {
            const formattedValue = `${floatValue.toFixed(2)}%`;
            setPrcntgm(formattedValue);
        }
    };

    const handleAnexo = async () => {
        console.log('Enviando...')
        const seuComponentePDF = <TrusteeSemGarantia dados={dados} />;

        const blob = await pdf(seuComponentePDF).toBlob();

        const formData = new FormData();
        formData.append('file', blob, 'documento.pdf'); // 'documento.pdf' é o nome do arquivo
        console.log(formData, 'formData');
        const objetoJson = {
            userRef: user.data.email,
            emissor: emissor,
            investidor: investidor,
            valorOperacao: formValues.valorTotal,
            parcelas: formValues.parcelas,
        }

        formData.append('jsonDados', JSON.stringify(objetoJson));

        // Fazendo a requisição para o servidor
        fetch('http://localhost:6024/minuta/', {
            method: 'POST',
            body: formData,
        })
            .then(response => response.json())
            .then(data => {
                console.log('Resposta do servidor:', data);
            })
            .catch(error => {
                console.error('Erro ao enviar o PDF e os dados:', error);
            });
    };

    return (
        <body className="homeBody">
            <div className="fixed">
                <Header selected={'docgen'} />
            </div>
            <div className="mainDoc">
                <div className="titlee">
                    <h1>Emitir Nota comercial</h1>
                </div>

                <div className="mainDoc1" style={{
                    marginLeft: '4%',
                }}>

                    <h2>INDEXADOR / CONTROLE DE EMISSÃO</h2>
                    <div className="rowDivs1">
                        <div className="divInputsDoc5">
                            <label htmlFor="" className="labelInputsDoc">
                                Controle de Emissão
                            </label>
                            <select
                                className="selectDoc"
                                value={emissao}
                                onChange={(e) => { setEmissao(e.target.value) }}
                            >
                                {numerosOrdinais.map((ordinal) =>
                                    <option value={ordinal}>{ordinal}</option>
                                )}
                            </select>
                        </div>
                        <div className="divInputsDoc5">
                            <label htmlFor="" className="labelInputsDoc">
                                Indexador
                            </label>
                            <select
                                className="selectDoc"
                                value={indexador}
                                onChange={(e) => { setIndexador(e.target.value) }}
                            >
                                {indexadores.map((ind) =>
                                    <option value={ind.key}>{ind.value}</option>
                                )}
                            </select>
                        </div>
                        {indexador === 'Pré-Fixado' || indexador ==='Pre' ? null :
                            <div className="divInputsDoc1">
                                <label htmlFor="" className="labelInputsDoc" style={{ width: '92%' }}>
                                    Defina a porcentagem do indexador
                                </label>
                                <NumericFormat
                                    className='inputsDoc'
                                    value={''}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    suffix={'%'}
                                    allowNegative={false}
                                    isNumericString={true}
                                    placeholder={`${prcntgm}%`}
                                    onValueChange={(values) => {
                                        setPrcntgm(values.value)
                                    }}
                                    required
                                />
                            </div>
                        }
                        {indexador ==='IPCA' && laqusNote ?
                        <>
                            <div className="divInputsDoc1">
                                <label htmlFor="" className="labelInputsDoc" style={{ width: '92%' }}>
                                    Dia de Aniversário
                                </label>
                                <input 
                                    type="number"
                                    min={1}
                                    className='inputsDoc'
                                    value={ipcaCase.diaAniversario} 
                                    onChange={handleChangeDiaAniversario} 
                                    placeholder=""
                                />
                            </div> 
                            <div className="divInputsDoc5">
                                <label htmlFor="" className="labelInputsDoc">
                                    Pró Rata
                                </label>
                                <select
                                    className="selectDoc"
                                    value={ipcaCase.proRata}
                                    onChange={(e) => {
                                        setIpcaCase({
                                              ...ipcaCase,
                                            proRata:e.target.value
                                        });
                                    }}
                                >
                                    <option value="Corrido">Corrido</option>
                                    <option value="Util">Útil</option>
                                </select>
                            </div>
                            <div className="divInputsDoc5">
                                <label htmlFor="" className="labelInputsDoc">
                                    Tipo de Projeção
                                </label>
                                <select
                                    className="selectDoc"
                                    value={ipcaCase.tipoProjecao}
                                    onChange={(e) => {
                                        setIpcaCase({
                                            ...ipcaCase,
                                            tipoProjecao:e.target.value
                                        });
                                    }}
                                >
                                    <option value="Anbima">Anbima</option>
                                    <option value="RepeteUltimo">Repete Último</option>
                                </select>
                            </div>
                        </>
                            : null
                        }
                        {modoCnabExpandido &&
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                                    <label style={{ marginBottom: '10px', width: '100%', textAlign: 'center' }}>
                                        Código da Escrituração (Apenas para CNAB)
                                    </label>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%', alignContent: 'flex-start', alignItems: 'flex-start' }}>
                                        {fourDigit.map((digit, index) => (
                                            <input
                                                key={index}
                                                className="inputsQuadradinho"
                                                value={digit}
                                                onChange={(e) => handleFourDigit(index, e.target.value)}
                                                onKeyDown={(e) => handleKeyDown(index, e)}
                                                maxLength="1"
                                                ref={(input) => (inputRefs.current[index] = input)}
                                            />
                                        ))}
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>
                                        Número do Título
                                    </label>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '90%' }}>
                                        <input
                                            className="inputsDoc"
                                            value={controleParticipante}
                                            onChange={(e) => setControleParticipante(e.target.value)}
                                            maxLength={24}
                                        />
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>
                                        Código do Originador (Consultoria)
                                    </label>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '90%' }}>
                                        <input
                                            className="inputsDoc"
                                            value={codigoId}
                                            onChange={(e) => setCodigoId(e.target.value)}
                                            maxLength={20}
                                        />
                                    </div>

                                </div>


                            </div>
                        }
                        {sendingNote ? (
                            <PostNote onNotePosted={() => setSendingNote(false)} />
                        ) : (
                            <div className="rowDivs1">
                                <div className="DocGenHeader">
                                    <div onClick={toggleModoSingulare} className="DocGenButton">
                                        {modoSingulareExpandido ? <div className="divIconAndText">Fechar Minuta <AiOutlineUp /></div> : <div className="divIconAndText">Emitir Minuta<AiOutlineDown /></div>}
                                    </div>
                                    {modoSingulareExpandido && (
                                        <div className="buttonsContainer">
                                            <div onClick={() => handleAvailable('oslo')} className="docButton1">
                                                {osloSemGarantia||osloComGarantia ? 'Fechar Nota Comercial' : 'Modelo Oslo'}
                                            </div>
                                            {user.data.cnpj === '67030395000146' ? null : <>
                                                <div onClick={() => handleAvailable('semGarantiaLaqus')} className="docButton1">
                                                    {semGarantiaLaqus ? 'Fechar Nota Comercial Laqus' : 'Modelo Laqus'}
                                                </div>
                                                <div onClick={() => handleAvailable('semGarantia')} className="docButton1">
                                                    {semGarantia ? 'Fechar Nota Comercial Singulare' : 'Modelo Singulare'}
                                                </div>
                                            </>}
                                            <div onClick={() => handleAvailable('trusteeSemGarantia')} className="docButton1">
                                                {trusteeSemGarantia ? 'Fechar Nota Comercial' : 'Modelo Trustee'}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="DocGenHeader">
                                    <div onClick={toggleModoVortx} className="DocGenButton">
                                        {modoVortxExpandido ? <div className="divIconAndText">Fechar Condições <AiOutlineUp /></div> : <div className="divIconAndText">Emitir Condições <AiOutlineDown /></div>}
                                    </div>
                                    {modoVortxExpandido && (
                                        <div className="buttonsContainer">
                                            {user.data.cnpj === '67030395000146' ? null : <>
                                                <div onClick={() => handleAvailable('available')} className="docButton1">
                                                    {available ? 'Fechar Condições Negociais' : 'Modelo Singulare'}
                                                </div>

                                            </>}
                                            <div onClick={() => handleAvailable('trusteeAvailable')} className="docButton1">
                                                {vortxAvailable ? 'Fechar Condições Negociais' : 'Modelo Trustee'}
                                            </div>
                                        </div>
                                    )}
                                    <div className="DocGenDiv">
                                        {osloSemGarantia?
                                            <div className={osloSemGarantia ? "pdf-container active" : "pdf-container"}>
                                                <OsloSemGarantia dados={dados} />
                                                <div onClick={(e) => { setOsloSemGarantia(false) }} className="close-button1">X</div>
                                            </div>
                                        :null}
                                        {osloComGarantia?
                                            <div className={osloComGarantia ? "pdf-container active" : "pdf-container"}>
                                                <OsloComGarantia dados={dados} />
                                                <div onClick={(e) => { setOsloComGarantia(false) }} className="close-button1">X</div>
                                            </div>
                                        :null}
                                        {semGarantiaLaqus ?
                                            <div className={semGarantiaLaqus ? "pdf-container active" : "pdf-container"}>
                                                <LaqusSemGarantia dados={dados} />
                                                <div onClick={(e) => { handleAvailable('semGarantiaLaqus') }} className="close-button1">
                                                    X
                                                </div>
                                        </div> : null}
                                        {available ?
                                            <div className={available ? "pdf-container active" : "pdf-container"}>
                                                <SingularePdf1 dados={dados} />
                                                <div onClick={(e) => { handleAvailable('available') }} className="close-button1">
                                                    X
                                                </div>
                                            </div> : null}
                                        {semGarantia ?
                                            <div className={semGarantia ? "pdf-container active" : "pdf-container"}>
                                                <SingulareSemGarantia dados={dados} />
                                                <div onClick={(e) => { handleAvailable('semGarantia') }} className="close-button1">X</div>
                                            </div> : null}

                                        {trusteeSemGarantia ?
                                            <div className={trusteeSemGarantia ? "pdf-container active" : "pdf-container"}>
                                                <PDFViewer style={{ width: '90%', height: '90%' }}>
                                                    <TrusteeSemGarantia dados={dados} />
                                                </PDFViewer>
                                                <div onClick={(e) => { setTrusteeSemGarantia(false) }} className="close-button1">X</div>
                                            </div>
                                            : null}

                                        {trusteeComGarantia ?
                                            <div className={trusteeComGarantia ? "pdf-container active" : "pdf-container"}>
                                                <TrusteeComGarantia dados={dados} />

                                                <div onClick={(e) => { handleAvailable('trusteeComGarantia') }} className="close-button1">X</div>
                                            </div>
                                            : null}
                                        {vortxAvailable ?
                                            <div className={vortxAvailable ? "pdf-container active" : "pdf-container"}>
                                                <div onClick={(e) => { handleAvailable('vortxAvailable') }} className="close-button1">X</div>
                                                <VortxPdf dados={dados} />
                                            </div>
                                            : null}
                                        {vortxSemGarantia ?
                                            <div className={VortxSemGarantia ? "pdf-container active" : "pdf-container"}>
                                                <div onClick={(e) => { handleAvailable('vortxSemGarantia') }} className="close-button1">X</div>
                                                <VortxSemGarantia dados={dados} />
                                            </div>
                                            : null}
                                        {trusteeAvailable ?
                                            <div className={trusteeAvailable ? "pdf-container active" : "pdf-container"}>
                                                <div onClick={(e) => { handleAvailable('trusteeAvailable') }} className="close-button1">X</div>
                                                <TrusteeCondicoes dados={dados} />
                                            </div>
                                            : null}
                                    </div>

                                </div>
                                <div className="DocGenHeader">
                                    <div onClick={toggleModoCnab} className="DocGenButton">
                                        {modoCnabExpandido ? <div className="divIconAndText">Fechar CNAB <AiOutlineUp /></div> : <div className="divIconAndText">Gerar CNAB <AiOutlineDown /></div>}
                                    </div>
                                    {modoCnabExpandido && (
                                        <div className="buttonsContainer">
                                            <div onClick={() => handleCnab()} className="docButton1">
                                                {semGarantia ? 'Fechar Nota CNAB Singulare' : 'Modelo Singulare'}
                                            </div>

                                            <div onClick={() => window.alert('Em Breve!')} className="docButton1">
                                                {trusteeSemGarantia ? 'Fechar Nota Comercial' : 'Modelo Trustee'}
                                            </div>
                                        </div>
                                    )}
                                </div>

                            </div>
                        )} </div>
                        <LaqusNotes enable={laqusNote} emitNote={handleEmissaoLaqus} buttonDisable={enviandoEscri}/>
                </div>
                <div style={{ marginBottom: '20vh', display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                    {sendingNote ? (
                        <button onClick={() => setSendingNote(false)} style={{ marginRight: '10px' }}>
                            {"Cancelar Envio"}
                        </button>
                    ) : (
                        <button onClick={() => {setSendingNote(true);setLaqusNote(false)}} style={{ marginRight: '10px' }}>
                            Solicitar Emissão de Nota Comercial
                        </button>
                    )}

                    {/* <button
                        onClick={handleEscri}
                        style={{
                            textAlign: 'center',
                            color: '#ffffff',
                            border: 'none',
                            padding: '10px',
                            cursor: 'pointer',
                        }}
                        disabled={enviandoEscri}
                    >
                        {enviandoEscri ? (
                            <div>
                                <h1 style={{ fontSize: '14px' }}>Conectando com sistemas it4 {'\n'}</h1>
                                <ClipLoader color="#ffffff" loading={enviandoEscri} css={override} size={15} />
                            </div>
                        ) : (
                            'Integrar com sistema IT4FINANCE'
                        )}
                    </button> */}
                    <LaqusButton 
                        onClick={handleCadastrosLaqus} 
                        loaderCss={override} 
                        isLoading={enviandoEscri}  
                        buttonText={'Integrar com sistema LAQUS'}
                        loadingText={'Conectando com sistemas LAQUS'} c
                        cancelText={'Cancelar'} 
                        isOpenFileList={laqusNote}
                        closeFileList={closeFileListLaqus}
                    />
                </div>
            </div>
            <ModalPopup
                    isOpen={modalIsOpen} 
                    onRequestClose={() => setModalIsOpen(false)}
                    customStyles={{display:"flex", justifyContent:"center"}}
            >
                <h2 style={{textAlign:"center"}}>
                    Cadastros envolvidos na operação estão com campos pendêntes
                </h2>
                {pendeciasLaqus.investidor.length||pendeciasLaqus.investidorLaqus.length?
                    <h3 style={{textAlign:"center", marginBottom:0}}>
                        Investidor
                    </h3>
                :""}
                    {pendeciasLaqus.investidor.length?
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection:"column",
                        }}>
                        <p style={{
                            fontSize:"1.1rem",
                            maxWidth:"500px",
                            backgroundColor: "lightgrey",
                            }}>
                            {pendeciasLaqus.investidorLaqus}
                        </p>
                        </div>
                    :""}
                    {pendeciasLaqus.investidor.length?
                    <>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <div style={{
                                fontSize:"1.1rem",
                                }}>
                                {pendeciasLaqus.investidor.map((pendencia)=>{
                                    return (<p style={{marginBottom:"auto"}}>
                                {pendencia.message}
                                </p>);
                                })}
                            </div>
                        </div>
                    </>
                    :""}
                    {pendeciasLaqus.emissor.length||pendeciasLaqus.emissorLaqus.length?
                        <h3 style={{textAlign:"center", marginBottom:0}}>
                            Emissor: {emissor?.razaoSocial}
                        </h3>
                    :""}
                    {pendeciasLaqus.emissorLaqus.length?
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection:"column",
                        }}>
                        <p style={{
                            fontSize:"1.1rem",
                            maxWidth:"500px",
                            backgroundColor: "lightgrey",
                            }}>
                            {pendeciasLaqus.emissorLaqus}
                        </p>
                        </div>
                    :""}
                    {pendeciasLaqus.emissor.length?
                    <>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <div style={{
                                fontSize:"1.1rem",
                                }}>
                                {pendeciasLaqus.emissor.map((pendencia)=>{
                                    return (<p style={{marginBottom:"auto"}}>
                                {pendencia.message}
                                </p>);
                                })}
                            </div>
                        </div>
                    </>
                    :""}
            </ModalPopup>

            <ModalPopup
                    isOpen={pendeciasLaqus.emissaoLaqusFail} 
                    onRequestClose={() => atualizarPendenciasLaqus({emissaoLaqusFail:false})
                    }
                    customStyles={{display:"flex", justifyContent:"center"}}
            >
                    <h2 style={{textAlign:"center"}}>
                        Solicitação não completada
                    </h2>
                    {pendeciasLaqus.emissaoLaqus.length?
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection:"column",
                        }}>
                        <p style={{
                            fontSize:"1.1rem",
                            maxWidth:"500px",
                            backgroundColor: "lightgrey",
                            }}>
                            {pendeciasLaqus.emissaoLaqus}
                        </p>
                        </div>
                    :""}

            </ModalPopup>
        </body>
    )
}

export default DocGen;