import React, { useState } from "react";
import Modal from "react-modal";

const ModalPopup = ({ isOpen, onRequestClose, children, customStyles }) => {
  const [open, setState] = useState(false)
  const defaultStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '20px',
      background: '#fff',
      borderRadius: '8px',
      minWidth: '50vw',
      overflowY: 'auto',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{ ...defaultStyles, ...customStyles }} // Permite sobrescrever estilos
      contentLabel="Modal"
    >
      <div>{children}</div>

      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <button onClick={onRequestClose} className="button">Fechar</button>
      </div>

    </Modal>

  );
};

export default ModalPopup;