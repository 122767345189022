import { apenasDigitos, convertToNumber } from "./Tools";

const laqusBuilder = ({
    formValues,
    linhaDoVencimento,
    calcData,
    investidor,
    allInfoArray,
    indexador,
    prcntgm,
    emissor,
    ipcaCase,
    numeroDaEmissao
}) => {

    let custoDaEmissao = parseFloat(formValues.taxaEmissao) + parseFloat(formValues.assinaturaDigital) + parseFloat(formValues.tac) + parseFloat(formValues.alienacao);
    const dataVencimento = `${linhaDoVencimento[3]}-${linhaDoVencimento[2]}-${linhaDoVencimento[1]}`;
    let parcelasTratadas = calcData.map((data) =>{
        const dataVencimentoParcela = `${data[3]}-${data[2]}-${data[1]}`;
        const valorTotalParcela = parseFloat(data[4]).toFixed(2);
        const valorAmortizacaoParcela = parseFloat(data[5]).toFixed(2);
        const valorJurosParcela = parseFloat(data[6]).toFixed(2);
        return {
            "dataVencimento": dataVencimentoParcela,
            "valorTotal": valorTotalParcela,
            "valorAmortizacao": valorAmortizacaoParcela,
            "valorJuros": valorJurosParcela,
            "isIncorporacaoJuros": true,
        }
    });
    let investidorNota = {
        "cpfCnpj": apenasDigitos(investidor.cnpj),
        "cnpjDoCustodiante": apenasDigitos(investidor.cnpj),
        "precoUnitario": convertToNumber(allInfoArray[5]),
        "quantidadeSubscrita": 1
    };
    if(investidor?.cnpjCustodiante && investidor?.cnpjCustodiante?.length >0){
        investidorNota.cnpjDoCustodiante = apenasDigitos(investidor.cnpjCustodiante);
    }
    let percentualDoIndexador = '0';
    if(indexador ==="Pre"){
        percentualDoIndexador = parseFloat(formValues.juros/100).toFixed(2);
    }else{
        percentualDoIndexador = prcntgm/100;
    }
    /**
     * Convencao_30_360 = "base exp, calendário 30/360
     * Convencao_dc_360 = "base exp, calendário corrido base 360 *
     * Convencao_dc_365 = "base exp, calendario 365
     * Convencao_du_252 = base exp, calendário Dias Úteis * 
     * Convencao_dc_30 = base ao mês corrido
     */
    let convencao='';
    if(formValues.modeloCalculo === "Regular" && formValues.dias === 30 ){
        convencao = "Convencao_30_360";
    }
    if(formValues.modeloCalculo === "Irregular" && formValues.dias === 30 ){
        convencao = "Convencao_dc_360";
    }
    if(formValues.dias === 21){
        convencao = "Convencao_du_252";
    }
    let percentualDosJurosRemuneratorios = parseFloat(formValues.emissaoPorcento/100).toFixed(6);
    let dadosFinanceiros = {
        "custoDaEmissao": custoDaEmissao,
        "valorNominalUnitario": parseFloat(formValues.valorTotal),
        "quantidade": formValues.parcelas,
        "percentualDosJurosRemuneratorios": parseFloat(percentualDosJurosRemuneratorios),
        "percentualDoIndexador": parseFloat(percentualDoIndexador),
        "indexador": indexador,
        "convencao": convencao,
        "tipoDePagamento": "TabelaPrice",
        "localDePagamento": emissor.cidade,
        "ufDePagamento": emissor.estado 
    }
    if(indexador ==="IPCA"){
        dadosFinanceiros.proRata = ipcaCase.proRata;
        dadosFinanceiros.tipoProjecao = ipcaCase.tipoProjecao;
        dadosFinanceiros.diaAniversario = ipcaCase.diaAniversario;
    }
    let laqusData = {
        "notaComercial": {
            "tipo": "NotaComercial",
            "dataDaEmissao": formValues.dataEmissao,
            "dataDoVencimento": dataVencimento,
            "numeroDaEmissao": numeroDaEmissao,
            "numeroDaSerie": 1,
            "localDaEmissao": emissor.cidade,
            "ufDaEmissao": emissor.estado,
            "cnpjDoEmissor":  apenasDigitos(emissor.cnpj),
            "cnpjDoEscriturador": "15224663000167",
            "investidores": [
                investidorNota
            ]
        },
        "dadosFinanceiros": dadosFinanceiros,
        "parcelas": parcelasTratadas
    }

    return  laqusData;
}

export default laqusBuilder;