import React from "react";
import { GrDocumentUpdate } from "react-icons/gr";
import '../styles/Investidors.css';
import { IoIosDownload } from "react-icons/io";

function InvestidorModal({ selectedEmissor, handleSave, onClose }) {
    let isLinkPresente = '';
    let isLinkPresente2 = '';
    let isLinkPresente3 = '';
    console.log(selectedEmissor,'selectedEmissor')
    if (selectedEmissor && selectedEmissor.realFaturamento) {
       isLinkPresente = selectedEmissor.realFaturamento !== undefined;
    }
    if (selectedEmissor && selectedEmissor.conSocial) {
     isLinkPresente2 = selectedEmissor.conSocial !== undefined;
    }
    if (selectedEmissor && selectedEmissor.compEndereco) {
    isLinkPresente3 = selectedEmissor.compEndereco !== undefined;
    }
    return (

        <div>
            <div className="modal">
                <div className="modal-content">
                    <button onClick={onClose} className="modalClose">X</button>
                    <h3 className="per1">Detalhes do Investidor Selecionado:</h3>

                    <div className="investidorContainer">
                        <div className="investidorField">
                            <div className="invesP">Razão Social:</div>
                            <div className="invesP1">{selectedEmissor?.razaoSocial}</div>
                        </div>
                        <div className="investidorField">
                            <div className="invesP">CNPJ:</div>
                            <div className="invesP1">{selectedEmissor?.cnpj}</div>
                        </div>
                        <div className="investidorField representantesView" >
                            <div className="invesP">Representantes:</div>
                            {selectedEmissor?.representantes.map((represent)=>{
                                return (
                                    <RepresentanteItemView className={"invesP1 invesRepresentante"} represent={represent}/>
                                )
                            })}
                        </div>
                        <div className="investidorField">
                            <div className="invesP">Endereço:</div>
                            <div className="invesP1">
                                CEP: {selectedEmissor?.cep},<br />
                                Rua: {selectedEmissor?.rua},<br />
                                Bairro: {selectedEmissor?.bairro},<br />
                                Cidade: {selectedEmissor?.cidade},<br />
                                UF: {selectedEmissor?.estado}
                            </div>
                        </div>
                        <div className="investidorField">
                            <a  href={isLinkPresente ? selectedEmissor.realFaturamento : '#'} className={`customFileInput2 ${isLinkPresente ? '' : 'opacoButton'}`} download={isLinkPresente ? 'NomeDoArquivoParaDownload' : ''} target="_relFat">
                                <IoIosDownload style={{ marginLeft: '20px' }} className="downloadIcon" />
                                <span className="downloadText">{`Baixar Relatório de Faturamento`} </span>
                            </a>
                        </div>
                        <div className="investidorField">
                            <a href={isLinkPresente2 ? selectedEmissor.conSocial : '#'} className={`customFileInput2 ${isLinkPresente2 ? '' : 'opacoButton'}`} target="_conSocial" >
                                <IoIosDownload style={{ marginLeft: '20px' }} className="downloadIcon" />
                                <span className="downloadText">{`Baixar Contrato Social`} </span>
                            </a>
                        </div>
                        <div className="investidorField">
                            <a href={isLinkPresente3 ? selectedEmissor.compEndereco : '#'} className={`customFileInput2 ${isLinkPresente3 ? '' : 'opacoButton'}`} target="_compEnd" >
                                <IoIosDownload style={{ marginLeft: '20px' }} className="downloadIcon" />
                                <span className="downloadText">{`Baixar Comprovate de Endereço`} </span>
                            </a>
                        </div>
                    </div>

                    <div style={{ display: 'flex', width: '100%', justifyContent: 'start' }}>
                        <button onClick={() => { handleSave(selectedEmissor) }} className="newButtonInves">Selecionar Investidor para Nota Comercial</button>
                    </div>

                </div>
            </div>
        </div >
    )
}

function RepresentanteItemView({represent, className}) {

     const procurador = (represent?.procurador && represent?.procurador == !undefined);

    return (
        <>
            <div className={className} >
                <p>
                    Nome: {represent.nome}
                </p>
                {(represent?.cpf)?
                <p> 
                    CPF: {represent.cpf}
                </p>
                :''}
                {(represent?.email)?
                <p> 
                    E-mail:<span> {represent.email}</span>
                </p>
                :''}
                <div className="invesRepresentanteFile">
                    <a href={represent?.idDocumento ? represent.idDocumento : ''} 
                        className={`customFileInput2 ${represent?.idDocumento ? '' : 'opacoButton'}`}
                        target="_idDoc" 
                    >
                        <IoIosDownload style={{ marginLeft: '20px' }} className="downloadIcon" />
                        <span className="downloadText">{`Documento de Identificação`} </span>
                    </a>
                </div>
                <div className="invesRepresentanteFile">
                    <a href={procurador ? represent.procuracao : ''} 
                        className={`customFileInput2 ${procurador ? '' : 'opacoButton'}`} 
                        target="_proc" 
                    >
                        <IoIosDownload style={{ marginLeft: '20px' }} className="downloadIcon" />
                        <span className="downloadText">{`Procuração`} </span>
                    </a>
                </div>
                {/* 
                invesRepresentanteFile
                investidorField
                 */}
            </div>
        </>
    );
}

export default InvestidorModal;
