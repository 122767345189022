const apiSimples = {
    generateToken: async () => {
        console.log('Gerando token...');
        const username = 'e10adc3949ba59abbe56e057f20f883e';
        const password = '8d969eef6ecad3c29a3a629280e686cf0c3f5d5a86aff3ca12020c923adc6c92';

        const authHeader = 'Basic ' + btoa(username + ':' + password);

        try {
            const response = await fetch('https://api.apisimples.com.br/v1/auth/generateToken', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authHeader,
                },
            });

            if (response.ok) {
                const data = await response.json();
                const token = data.token;
                console.log('Token gerado:', token);
                return token;
            } else {
                console.error('Erro ao gerar o token:', response.statusText);
                throw new Error('Erro ao gerar token');
            }
        } catch (error) {
            console.error('Erro na requisição:', error.message);
            throw new Error('Erro na requisição ao gerar token');
        }
    },
    selectBilletByIde: async (ide) => {
        const token = await apiSimples.generateToken();
        console.log('Selecionando billet por ide...');
        console.log('Ide:', ide);
        // Validar se as linhas e ide são fornecidos   
        if (!ide) {
            throw new Error('O ide é obrigatório.');
        }

        const billetRequestBody = {
        };

        try {
            const response = await fetch(`https://api.apisimples.com.br/v1/process_billet/selectIde`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'ide': `${ide}`,
                },
                body: JSON.stringify(billetRequestBody),
            });

            if (response.ok) {
                const billetData = await response.json();
                console.log('Billet selecionado por ide:', billetData);
                return billetData;
            } else {
                console.error('Erro ao selecionar o billet por ide:', response.statusText);
                throw new Error('Erro ao selecionar o billet por ide');
            }
        } catch (error) {
            console.error('Erro na requisição:', error.message);
            throw new Error('Erro na requisição ao selecionar o billet por ide');
        }
    },
    selectBillet: async (token, linhas) => {
        console.log('Selecionando billet...');

        // Validar se as linhas são fornecidas
        if (!linhas || linhas.length === 0) {
            throw new Error('O array de linhas é obrigatório e não pode estar vazio.');
        }

        const billetRequestBody = {
            "linha": linhas, // Utiliza o array de linhas fornecido como parâmetro
          //  "url": "https://c2easybe-production.up.railway.app/webhook",
            "url": process.env.REACT_APP_BASE_URL+'/webhook',
        };

        try {
            const response = await fetch('https://api.apisimples.com.br/v1/process_billet/create', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, // Use Bearer token para autenticação
                },
                body: JSON.stringify(billetRequestBody),
            });

            if (response.ok) {
                const billetData = await response.json();
                console.log('Billet selecionado:', billetData);
                return billetData;
            } else {
                console.error('Erro ao selecionar o billet:', response.statusText);
                throw new Error('Erro ao selecionar o billet');
            }
        } catch (error) {
            console.error('Erro na requisição:', error.message);
            throw new Error('Erro na requisição ao selecionar o billet');
        }
    },
};

export default apiSimples;
