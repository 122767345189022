import { useDispatch, useSelector } from 'react-redux';
import { setFinalTotal, setCalcData, setSacData } from '../redux/actions';
import { MdOutlineSettingsBackupRestore } from 'react-icons/md';
import { parseDateHyphen } from './Tools';

export function useCalculoTabela() {
    const dispatch = useDispatch();
    const dados = useSelector((state) => state.dados.dados);
    const calcUtil = JSON.parse(localStorage.getItem('formValues'));
    const sacData = useSelector((state) => state.dados.sacData);
    let user = dados.data?.nome ?? 'teste';
    const valorInserido = JSON.parse(localStorage.getItem('valorInserido'));


    async function calcularTabela(
        assinatura,
        modelo,
        baseDias,
        total,
        taxa,
        parcelas,
        carencia,
        custoCapital,
        taxaEmissao,
        vencimentoDia,
        vencimentoMes,
        vencimentoAno,
        emissaoCCB,
        tac,
        alienacao,
        tipocarencia,
        dataEmissao,
        rentabilidade
    ) {
        dispatch(setSacData([]))

        const valorInicial = parseFloat(total);
        const taxaFloat = parseFloat(taxa.replace(',', '.'));
        const taxaCCB = parseFloat(emissaoCCB)
        const capital = parseFloat(custoCapital);
        const emissao = parseFloat(taxaEmissao);
        const tac1 = parseFloat(tac);
        const alienacao1 = parseFloat(alienacao);
        let carenciaIteracao = parseInt(carencia);
        let carenciaDefault = parseInt(carencia);
        const custoAssinatura = parseFloat(assinatura);
        let tipoCarencia2 = tipocarencia;
        let quantidadeParcelas = parseInt(parcelas);
        let quantidadeParcelas2 = parseInt(parcelas);

        const jurosMensais = taxaFloat / 100;

        const capitalTaxa = capital / 100;

        let valorNovo = valorInicial
        let valorI = valorInicial + emissao + tac1 + alienacao1 + custoAssinatura;
        let valorI2 = valorInicial + emissao + tac1 + alienacao1 + custoAssinatura;

        //rentabilidade
        if (rentabilidade === 'sim' && calcUtil) {
            let emissao1 = parseFloat(calcUtil.taxaEmissao);
            let tac2 = parseFloat(calcUtil.tac);
            let alienacao2 = parseFloat(calcUtil.alienacao);
            valorNovo = valorInicial - emissao1 - tac2 - alienacao2;
            valorI -= emissao1;
        }

        let saldoDevedor = valorI; //saldo devedor soma valor da operação,taxa de emissao, TAC,aliencacao,custo assinatura
        let inicial = valorInicial + emissao + tac1 + alienacao1 + custoAssinatura;

        // Calculo e formatacao das datas 
        const dataInicial = new Date(vencimentoAno, vencimentoMes, vencimentoDia);
        const diaInicial = dataInicial.getDate() + 1;
        const mesInicial = dataInicial.getMonth();
        const mesInicial1 = dataInicial.getMonth() + 1;
        const anoInicial = dataInicial.getFullYear();

        const formattedDate = `${diaInicial < 10 ? "0" + diaInicial : diaInicial}/${mesInicial1 < 10 ? "0" + mesInicial1 : mesInicial1}/${anoInicial}`;

        let dataVencimento = new Date(anoInicial, mesInicial, diaInicial);

        function diasEntreDatas(dataInicio, dataFim) {
            const umDia = 24 * 60 * 60 * 1000; // Um dia em milissegundos
            const diff = Math.abs(dataFim.getTime() - dataInicio.getTime());
            return Math.round(diff / umDia);
        }
        const dataAtual = new Date();

        const dataEmissaoObj = parseDateHyphen(dataEmissao);
 
        const diasPrimeiraParcela = diasEntreDatas(dataEmissaoObj, dataVencimento);  
        //Alteracao no saldo devedor 
        let saldoDevedorOriginal = saldoDevedor;
        let carenciaIteracao1 = parseInt(carencia);

        //Soma os juros no saldo devedor durante a carencia
        while (carenciaIteracao1 > 0) {
            quantidadeParcelas -= 1
            const jurosCarencia = saldoDevedorOriginal * jurosMensais;
            saldoDevedorOriginal += jurosCarencia;
            carenciaIteracao1 -= 1;
        }

        let jurosAD;
        if (baseDias === 21) {
            jurosAD = ((1 + jurosMensais) ** (1 / 21) - 1)
        }
        //Arredondar
        let somaJurosAD = baseDias === 21 ? (jurosAD * baseDias) : jurosMensais;

        //calculo da parcela para carencia total
        const iPrestacao = (((1 + somaJurosAD) ** quantidadeParcelas) * somaJurosAD)
        const nPrestacao = ((1 + somaJurosAD) ** quantidadeParcelas) - 1
        let valorParcela = (saldoDevedorOriginal * (iPrestacao / nPrestacao));

        //calculo da parcela com carencia na amortização
        let nPrestacao2 = quantidadeParcelas2 - carenciaIteracao
        let p = (valorI * jurosMensais) / (1 - (1 + jurosMensais) ** -nPrestacao2);
        if(tipoCarencia2 == 1 && carenciaDefault>0){
            valorParcela = p
         }

        //outros calculos
        const ccb = saldoDevedorOriginal * taxaCCB / 100;
        let totalJuros = 0;
        let custoFinanceiro = 0;
        let html = [];

        const diaOriginal = dataVencimento.getDate();
        const diasPrimeiraParcela1 = Math.max(0, Math.min(diasPrimeiraParcela, diasEntreDatas(dataEmissaoObj, dataVencimento)));

        // tipoCarencia2 === 1 ? carenciaIteracao = carenciaIteracao+=1 : carenciaIteracao = carenciaIteracao
        for (let mes = 1; mes <= parseInt(parcelas); mes++) {
            let vencimentoDiaAtual = dataVencimento.getDate();
            let vencimentoMesAtual = dataVencimento.getMonth() + 1;
            const vencimentoAnoAtual = dataVencimento.getFullYear();
            if (vencimentoMesAtual === 1 && (diaOriginal === 29 || diaOriginal === 30 || diaOriginal === 31)) {
                dataVencimento.setDate(28);
            }

            let juros, amortizacaoFinal, parcelaAtual;
            let financeiro = saldoDevedor * capitalTaxa;
            
            if (carenciaIteracao > 0) {
                //carencia por amortizacao  
                if(tipoCarencia2 == 1 && carenciaDefault>0){
                    saldoDevedor = saldoDevedor
                    juros = saldoDevedor * jurosMensais;
                    parcelaAtual = juros
                    amortizacaoFinal = 0;
                    inicial = saldoDevedor;
                    carenciaIteracao -= 1;
                }else{
                    //carencia total
                     parcelaAtual = 0;
                     juros = inicial * somaJurosAD;
                     amortizacaoFinal = 0;
                     inicial += juros;
                     saldoDevedor += juros;
                     carenciaIteracao -= 1;
                }
            }
            else {
                // console.log()

                if (mes === 1 && modelo === 'Irregular') {
                    console.log('modelo')
                    let some = inicial * (somaJurosAD / 30);
                    let diasCorrigido = diasPrimeiraParcela1;

                    if (baseDias === 21 && diasPrimeiraParcela1 > 21 && diasPrimeiraParcela1 < 31) {
                        diasCorrigido = 21;
                    }

                    if (baseDias === 30 && diasPrimeiraParcela1 > 30 && diasPrimeiraParcela1 < 31) {
                        diasCorrigido = 30;
                    }

                    juros = some * diasCorrigido;
                    let referencia = some * baseDias;
                    let diferenca = referencia - juros;

                    parcelaAtual = (valorParcela - diferenca);
                } else {
                    //juros para amortizar quando for carencia na amortização
                    if(tipoCarencia2 == 1 && carenciaDefault>0){
                        juros = inicial * jurosMensais;
                    }else{
                        juros = inicial * somaJurosAD;
                    }
                    parcelaAtual = valorParcela;
                }
               // console.log(juros,'juros')
               //começa a amortizar
               amortizacaoFinal = parcelaAtual - juros;
               inicial -= amortizacaoFinal;
               saldoDevedor -= amortizacaoFinal;
               custoFinanceiro += financeiro;
               if (saldoDevedor < 1) {
                   saldoDevedor = 0
               }
            }
            console.log(dataVencimento.getMonth(), 'texto')
            vencimentoMesAtual = (dataVencimento.getMonth() + 1).toString().padStart(2, '0');
            totalJuros += juros;
            html.push([
                mes, //0
                vencimentoDiaAtual,//1
                vencimentoMesAtual,
                vencimentoAnoAtual,
                parcelaAtual,
                amortizacaoFinal,
                juros,
                saldoDevedor
            ]);
            dataVencimento.setMonth(dataVencimento.getMonth() + 1);
            if (dataVencimento.getMonth() !== 1) {
                dataVencimento.setDate(diaOriginal);
            }
        }
        // console.log(html,'html')
        getInfos(valorI, valorParcela, custoFinanceiro, valorInicial, emissao, quantidadeParcelas, formattedDate, ccb, taxaFloat, totalJuros, valorI2, valorNovo, carencia);
        dispatch(setCalcData(html));
        localStorage.setItem('calcData', JSON.stringify(html));
    }

    function getInfos(calculo, valorParcela, jurosCapital, valorInicial, taxaEmissao, parcelas, formattedDate, ccb, taxaFloat, totalJuros, valorMenos, valorNovo, carencia) {

        const valor1 = calculo;
        const iof1 = valor1 * 0.0038;
        let iof2 = valor1 * 0.000041 * (parcelas * 30);
        const iofMax = valor1 * 0.015;
        if (iof2 >= iofMax) {
            iof2 = iofMax
        }
        const economiaIOF = iof1 + iof2;
        let ccbFinal = ccb + economiaIOF;

        const majorado = economiaIOF * (1 + (taxaFloat / 100)) ** parcelas;

        const ganhoReal = totalJuros - jurosCapital;
        const spread = totalJuros;
        let desagio = calculo - valorMenos;
        const margemBruta1 = ganhoReal / spread * 100;
        const vencimento = formattedDate
        const saldoDevedor = valor1;
        const saldoDevedor1 = valor1 + spread
        const taxaAoAno = ((1 + (taxaFloat / 100)) ** 12 - 1) * 100
        console.log(calcUtil,'calcUtil')
        const valorInalterado = parseFloat(calcUtil.valorTotal);

        const allInfoArray = [
            desagio.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), //0
            margemBruta1.toFixed(2), // 1
            taxaEmissao.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), // 2
            valorParcela.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), // 3
            parcelas, // 4
            valorInicial.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), // 5
            valorInalterado.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), // 6
            vencimento, // 7
            user, // 8
            jurosCapital.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), //9
            taxaFloat, // 10
            jurosCapital.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), //11
            saldoDevedor1.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), //12
            saldoDevedor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), // 13
            economiaIOF.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), //14
            ccbFinal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), // 15
            totalJuros.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), //16
            majorado.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), //17
            ganhoReal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), //18
            carencia, //19
            taxaAoAno.toFixed(2)
        ]

        dispatch(setFinalTotal(allInfoArray));
        localStorage.setItem('allInfoArray', JSON.stringify(allInfoArray));
    }

    return {
        calcularTabela
    }
}