import React, { useState, useEffect } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { NumericFormat } from "react-number-format";
import '../styles/Result.css';

const Modal = ({ isOpen, onClose, onConfirm, valorAtual, valorParaSomar }) => {
    const initialValorInserido = parseFloat(
        valorParaSomar ? valorParaSomar.replace(/[^\d,]/g, "").replace(",", ".") : "0"
    );
    const [valorInserido, setValorInserido] = useState(initialValorInserido);
    const [maxValue, setMaxValue] = useState(0);
    const [isValid, setIsValid] = useState(true);

    useEffect(() => {
        const numericValue = parseFloat(
            valorParaSomar ? valorParaSomar.replace(/[^\d,]/g, "").replace(",", ".") : "0"
        );
        setMaxValue(numericValue);
    }, [valorParaSomar]);

    const handleConfirm = () => {
        localStorage.setItem("valorInserido", valorInserido.toString());
        onConfirm(valorInserido);
    };

    const handleSliderChange = (newValue) => {
        setValorInserido(newValue);
    };

    const handleInputChange = (values) => {
        if (!values.value) {
            // Se o valor estiver vazio, defina-o como zero
            setValorInserido(0);
            setIsValid(true); // Defina como válido
        } else if (values.value <= maxValue) {
            setValorInserido(values.floatValue);
            setIsValid(true); // Defina como válido
        } else {
            setIsValid(false); // Defina como inválido
        }
    };

    const sliderStyles = {
        width: "60%",
        margin: "0 auto",
    };

    const trackStyles = {
        backgroundColor: "#1a2f67",
    };

    const handleStyles = {
        borderColor: "#92c01e",
        backgroundColor: "#92c01e",
    };

    const tooltipStyles = {
        backgroundColor: "#1a2f67",
        color: "white",
        border: "none",
    };

    const inputStyles = {
        backgroundColor: isValid ? "white" : "#ffcccc", // Altera a cor de fundo com base na validação
    };

    return isOpen ? (
        <div className="infoClass2">
            <div className="insideInfo">
                <h2>Rentabilidade</h2>
                <p className="pInfo">
                    Ganho Atual:{" "}
                    {valorAtual.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                    })}
                </p>
                <div className="pInfo">
                    Valor a ser adicionado:{" "}
                    <Slider
                        min={0}
                        max={maxValue}
                        step={0.01}
                        value={valorInserido}
                        onChange={handleSliderChange}
                        style={sliderStyles}
                        trackStyle={trackStyles}
                        handleStyle={handleStyles}
                        tipStyle={tooltipStyles}
                    />
                </div>
                <p className="pInfo">
                    <strong>Valor Selecionado:</strong>{" "}
                    <NumericFormat
                        value={valorInserido}
                        displayType={"input"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        prefix={"R$ "}
                        allowNegative={false}
                        onValueChange={handleInputChange}
                        style={inputStyles} // Aplica o estilo ao input
                    />
                </p>
                <button onClick={handleConfirm}>Confirmar</button>
                <button onClick={onClose}>Fechar</button>
            </div>
        </div>
    ) : null;
};

export default Modal;
