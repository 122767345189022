import React, { useState, useEffect } from 'react';
import apiSimples from '../utils/apiSimples';
import Header from '../components/Header';
import { FaPlus, FaRotate, FaSpinner, FaTrashCan } from 'react-icons/fa6';
import '../styles/Billets.css';

const Billets = () => {
    const [token, setToken] = useState('');
    const [linhas, setLinhas] = useState([]);
    const [showSuccessBox, setShowSuccessBox] = useState(false);
    const [inputValues, setInputValues] = useState(['']);
    const [notas, setNotas] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [ide, setIde] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const ide = localStorage.getItem('ide');
        if (ide) {
            setIde(ide);
        }
    })
    console.log('ide:', ide);

    useEffect(() => {
        if (notas.length === 0) {
            listarNotas('e724ef207130fecc4c80fba4b95d9754');
        }
    }, [notas]);

    const handleCadastroBillet = async () => {
        try {
            const generatedToken = await apiSimples.generateToken();
            setToken(generatedToken);

            const updatedLinhas = inputValues
                .map(value => value.replace(/[^\d]/g, ''))
                .filter(value => value.trim() !== '');

            setLinhas(updatedLinhas);

            if (updatedLinhas.length > 0) {
                const billetData = await apiSimples.selectBillet(generatedToken, updatedLinhas);

                if (billetData && billetData.status === 500) {
                    setErrorMessage('Servidor Fora do Ar');
                } else {
                    setErrorMessage('');
                    setShowSuccessBox(true);
                    console.log('BilletData:', billetData);
                    localStorage.setItem('ide', billetData.ide);
                    setErrorMessage(null)
                    listarNotas(billetData.ide);
                }
            } else {
                console.error('O array "linhas" não foi fornecido.');
            }
        } catch (error) {
            console.error('Erro ao cadastrar o boleto:', error.message);
            setErrorMessage('Erro ao buscar o boleto. Tente novamente mais tarde.');
        }
    };

    const handleAddInput = () => {
        setInputValues([...inputValues, '']);
    };

    const handleRemoveInput = (index) => {
        const newInputValues = [...inputValues];
        newInputValues.splice(index, 1);
        setInputValues(newInputValues);
    };

    const handleInputChange = (index, value) => {
        const newInputValues = [...inputValues];
        newInputValues[index] = value;
        setInputValues(newInputValues);
    };

    const formatInputValue = (rawValue) => {
        // Remover qualquer formatação existente
        const unformattedValue = rawValue.replace(/[^\d]/g, '');

        // Aplicar a máscara desejada
        return unformattedValue.replace(/(\d{5})(\d{5})(\d{5})(\d{1})(\d{14})/g, '$1.$2 $3.$4 $5');
    };

    const hideSuccessBox = () => {
        setShowSuccessBox(false);
    };

    const listarNotas = async () => {
        if (!ide) {
            setErrorMessage('Ide não encontrado, realize uma nova consulta');
        }
        try {
            setIsLoading(true);
            setErrorMessage(null)

            const response = await apiSimples.selectBilletByIde(ide)
            if (response && response.status === 500) {
                setErrorMessage('Servidor Fora do Ar');
            };
            setNotas(response.response.items);
        } catch (error) {
            console.error('Erro ao listar notas:', error.message);
        }
        finally {
            // Finaliza o carregamento, independentemente do resultado
            setIsLoading(false);
        }
    };

    const formatFieldName = (fieldName) => {
        // Substituir _ por espaço e converter para maiúsculas as primeiras letras
        return fieldName.replace(/_/g, ' ').replace(/\b\w/g, firstLetter => firstLetter.toUpperCase());
    };

    const getStatusText = (status) => {
        switch (status) {
            case '0':
                return 'Em Processamento';
            default:
                return status; // Você pode ajustar o valor padrão conforme necessário
        }
    };

    const formatValue = (fieldName, value) => {
        if (fieldName === 'linha_digitavel') {
            // Formatar a linha digitável com espaços e pontos
            return value.replace(/(\d{5})(\d{5})(\d{5})(\d{1})(\d{14})/g, '$1.$2 $3.$4 $5');
        } else if (fieldName === 'valor') {
            // Formatar valor como moeda
            return `R$ ${parseFloat(value).toFixed(2).replace('.', ',')}`;
        } else if (fieldName === 'vencimento' && value) {
            // Formatar data de vencimento
            const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
            return new Date(value).toLocaleDateString('pt-BR', options);
        } else if (fieldName === 'status') {
            // Substituir o status numérico pelo texto correspondente
            return getStatusText(value);
        } else {
            return value;
        }
    };

    return (
        <div style={{ padding: 10, maxWidth: '100%' }}>
            <div className="fixed">
                <Header selected={'billets'} />
            </div>
            <main style={{
                display: 'flex',
                flexDirection: 'column',
                maxHeight: '95vh',
                marginLeft: '55vh',
                maxWidth: '67%',
                marginTop: '5vh',
            }}>
                <div style={{
                    backgroundColor: 'white',
                    padding: '10px',
                    marginRight: '30px',
                    borderRadius: '5px',
                    width: '100%',
                    border: '1px solid #ccc',
                }}>
                    {inputValues.map((value, index) => (
                        <div key={index} style={{ marginBottom: '10px', display: 'flex', flexDirection: 'column' }}>
                            <h1 className='title'>Preencha com o número do código de barras para pesquisar</h1>
                            <label style={{
                                fontSize: '14px',
                                fontWeight: 'bold',
                                marginBottom: '5px',
                                marginLeft: '5px',
                            }}>{`Linha Digitável do Boleto ${index + 1}`}</label>
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <input
                                    type="text"
                                    value={value}
                                    className='inputHome'
                                    style={{ width: '90%' }}
                                    onChange={(e) => handleInputChange(index, e.target.value)}
                                />
                                <div onClick={() => handleRemoveInput(index)} style={{ cursor: 'pointer' }}>
                                    <FaTrashCan size={20} style={{ color: 'red' }} />
                                </div>
                            </div>

                        </div>
                    ))}
                    <div onClick={handleAddInput} style={{ cursor: 'pointer', marginTop: '10px', textAlign: 'center', color: '#1b2f69', padding: '5px', borderRadius: '5px', }}><FaPlus size={12} />Adicionar Linha</div>
                </div>
                <button onClick={handleCadastroBillet} style={{
                    width: '15%',
                    alignSelf: 'center',
                }}>Pesquisar</button>

                {/* Box de sucesso */}
                {showSuccessBox && (
                    <div
                        style={{
                            border: '1px solid green',
                            backgroundColor: 'white',
                            padding: '10px',
                            marginTop: '10px',
                            borderRadius: '5px',
                            color: 'green',
                        }}
                    >
                        Boleto Enviado!
                    </div>
                )}
                {errorMessage && (
                    <div
                        style={{
                            border: '1px solid red',
                            backgroundColor: 'white',
                            padding: '10px',
                            marginTop: '10px',
                            borderRadius: '5px',
                            color: 'red',
                        }}
                    >
                        {errorMessage}
                    </div>
                )}
                <div className="notas-container">
                    <h3>Boleto encontrado:</h3>
                    {notas.length > 0 ? (
                        <div>
                            {notas.map((nota, index) => (
                                <div key={index} className="nota-card">
                                    <div
                                        style={{
                                            display: 'flex',
                                            width: '10%',
                                            justifyContent: 'flex-end',
                                            alignItems: 'center',
                                            color: '#1b2f69',
                                            borderRadius: '5px',
                                            cursor: 'pointer',
                                        }}
                                        // Desativa o clique enquanto estiver carregando
                                        onClick={!isLoading ? () => listarNotas(ide) : undefined}
                                    >
                                        {isLoading ? (
                                            <FaSpinner style={{ padding: '5px' }} />
                                        ) : (
                                            <FaRotate style={{ padding: '5px' }} />
                                        )}
                                        <p>Atualizar</p>
                                    </div>
                                    <pre className="nota-details">
                                        {Object.entries(nota).map(([key, value]) => (
                                            key !== "_id" && key !== "token" && key !== "ide" && key !== "__v" && key !== "data_consulta" && key !== "data_retorno" && (
                                                <div key={key} className="nota-property">
                                                    <span className="nota-key">{formatFieldName(key)}:</span>
                                                    <span className="nota-value">{formatValue(key, value)}</span>
                                                </div>
                                            )
                                        ))}
                                    </pre>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p className="nenhuma-nota" onClick={() => { listarNotas(ide) }}>Procurar Boletos Na Memória</p>
                    )}
                </div>
            </main >
        </div >
    );
};

export default Billets;
