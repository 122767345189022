import React, { useState } from 'react';
import Header from './Header';
import { useSelector } from 'react-redux';

function Irregular() {
    const calcData1 = JSON.parse(localStorage.getItem('calcData'));
    const sacData1 = JSON.parse(localStorage.getItem('sacData'));
    const sacData2 = useSelector((state) => state.sacData.sacData)
    const calcData2 = useSelector((state) => state.calcData.calcData)
    console.log(calcData2, 'calcData2');
    let calcData = calcData2 ? calcData2 : null
    let sacData = sacData2 ? sacData2 : null
    const [tableData, setTableData] = useState(calcData); // Use calcData em vez de data

    const [editableTableData, setEditableTableData] = useState(calcData);


    const allInfoArray = JSON.parse(localStorage.getItem('allInfoArray'));
    const now = new Date();
    const handleAmortizationChange = (index, newValue) => {
        // Atualize os dados da tabela com o novo valor de amortização
        const updatedEditableTableData = [...editableTableData]; // Crie uma cópia da matriz original
        updatedEditableTableData[index] = [...updatedEditableTableData[index]]; // Crie uma cópia do objeto na posição
        updatedEditableTableData[index][4] = parseFloat(newValue); // Atualize o valor de amortização na cópia
        setEditableTableData(updatedEditableTableData); // Atualize o estado com a cópia atualizada
    };

    function getDayOfWeek(day, month, year) {
        const daysOfWeek = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];
        const date = new Date(year, month - 1, day); // Mês em JavaScript é base 0 (janeiro = 0, fevereiro = 1, etc.)
        const dayOfWeek = date.getDay(); // Isso retorna um número de 0 (Domingo) a 6 (Sábado)
        return daysOfWeek[dayOfWeek];
    }


    const formatCurrency = (value) => {
        return parseFloat(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    };

    return (
        <body className="resultBody">
            <div className='fixed'>
                <Header selected={'result'} />
            </div>
            <main className="resultMain">
                <h1 className="h1Doc">Tabela Irregular</h1>
                <div className="manyTable">
                    <table>
                        <thead>
                            <tr>
                                <th>Período</th>
                                <th>Vencimento</th>
                                <th className="direita">Dia da Semana</th>
                                <th>Valor da Parcela</th>
                                <th>Amortização</th>
                                <th>Juros</th>
                                <th>Saldo Devedor</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="centralizado">0</td>
                                <td className="centralizado">{now.getDate().toString().padStart(2, '0')}/{(now.getMonth() + 1).toString().padStart(2, '0')}/{now.getFullYear()}</td>
                                <td className="direita">0</td>
                                <td className="direita">0</td>
                                <td className="direita">0</td>
                                <td className="direita">{allInfoArray[13]}</td>
                            </tr>
                            {calcData ? calcData.map((data, index) => (
                                <tr key={index}>
                                    <td className="centralizado">{data[0]}</td>
                                    <td className="centralizado">
                                        {data[1] < 10 ? `0${data[1]}` : data[1]}/{data[2] === '00' ? 12 : data[2]}/{data[3]}
                                    </td>
                                    <td className="direita">{getDayOfWeek(data[1], data[2], data[3])}</td>

                                    <td className="direita">
                                        {data[4].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                    </td>
                                    <td className="direita">
                                        <input
                                            type="text"
                                            value={data[4].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                            onChange={(e) => handleAmortizationChange(index, e.target.value)}
                                        />
                                    </td>
                                    <td className="direita">
                                        {data[6].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                    </td>
                                    <td className="direita">
                                        {data[7].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                    </td>
                                </tr>
                            )) : null}
                            {sacData ? sacData.map((data, index) => (
                                <tr key={index}>
                                    <td className="centralizado">{data[0]}</td>
                                    <td className="centralizado">
                                        {data[1] < 10 ? `0${data[1]}` : data[1]}/{data[2] === '00' ? 12 : data[2]}/{data[3]}
                                    </td>
                                    <td className="direita">
                                        {data[4].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                    </td>
                                    <td className="direita">
                                        {data[5].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                    </td>
                                    <td className="direita">
                                        {data[6].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                    </td>
                                    <td className="direita">
                                        {data[7].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                    </td>
                                </tr>
                            )) : null}
                        </tbody>
                    </table>
                </div>
            </main>
        </body>
    );
}

export default Irregular;
