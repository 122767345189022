// ErrorPage.js
import React from 'react';
import '../styles/ErrorPage.css'; // Importe seu arquivo de estilos CSS

const ErrorPage = () => {
    return (
        <div className="error-page">
            <h2 className='h2Error'>Ops! Algo deu errado.</h2>
            <p className='pError'>Desculpe, ocorreu um erro. Por favor, tente novamente mais tarde.</p>
            <button className="error-button" onClick={() => window.location.replace('/home')}>
                Voltar para a página inicial
            </button>
        </div>
    );
};

export default ErrorPage;
