import React, { useEffect, useState } from "react";
import { Page, Text, View, Document, StyleSheet, Image, PDFViewer, Font } from "@react-pdf/renderer";
import { useSelector } from 'react-redux';
import { pdfStyle } from "./pdfStyles";
import { useNavigate } from "react-router-dom";
import  HyphenationCallback  from "./PdfHyphenation";
import { parseDateHyphen, estadoPorExtenso } from "../Tools";
Font.registerHyphenationCallback(HyphenationCallback);

const formatDate = (inputDate) => {
    const months = [
        "janeiro", "fevereiro", "março", "abril", "maio", "junho",
        "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"
    ];

    const dateParts = inputDate.split("-");
    const year = dateParts[0];
    const month = months[Number(dateParts[1]) - 1];
    const day = Number(dateParts[2]);

    return `${day} de ${month} de ${year}`;
};

export const nascimentoAvalista = (text) => {
    if (text) {
        const meses = [
            'janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho',
            'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'
        ];

        const year = text.substring(0, 4);
        const month = parseInt(text.substring(5, 7)) - 1; // os meses começam de 0 (janeiro) a 11 (dezembro)
        const day = text.substring(8, 10);

        return `${day} de ${meses[month]} de ${year}`;
    }
}


const formatCnpj = (text) => {
    if (text) {
        const cnpj = text.replace(/\D/g, '');
        let formattedCnpj = '';

        for (let i = 0; i < cnpj.length; i++) {
            if (i === 2 || i === 5) {
                formattedCnpj += '.';
            } else if (i === 8) {
                formattedCnpj += '/';
            } else if (i === 12) {
                formattedCnpj += '-';
            }
            formattedCnpj += cnpj[i];
        }

        return formattedCnpj;
    }
};

const LaqusSemGarantia = ({ dados }) => {
    const numero = require('numero-por-extenso');
    const calcData2 = useSelector((state) => state.calcData.calcData)
    const formValues = JSON.parse(localStorage.getItem('formValues'));
    const emissor = JSON.parse(localStorage.getItem('emissor'));
    const investidor = JSON.parse(localStorage.getItem('investidor'));
    console.log(emissor, 'emissor');
    console.log(investidor, 'investidor');
    const allInfoArray = useSelector((state) => state.finalTotal.finalTotal);
    const calcData1 = JSON.parse(localStorage.getItem('calcData'))
    const calcData = calcData2 ? calcData2 : calcData1
    const navigate = useNavigate();
    const [countTitle, setCountTitle] = useState(1)
    useEffect(() => {
        if (!emissor) {
            window.alert('O formulário do Emissor não foi preenchido!');
            navigate('/Emissor');
            return;
        }

        if (!investidor) {
            window.alert('O formulário do Investidor não foi preenchido!');
            return;
        }

        if (typeof calcData !== 'object') {
            window.alert('Cálculo não realizado!');
            return;
        }

    }, [emissor, investidor, navigate, calcData]);

    const valorTotal = allInfoArray[13];
    const now = formValues.dataEmissao ? parseDateHyphen(formValues.dataEmissao):new Date();
    const parcelas = allInfoArray[4]
    const dias = parcelas * 30;
    const currentZoom = window.devicePixelRatio;

    // Calcula o tamanho do componente PDFViewer com base no zoom
    const pdfViewerWidth = 2500 / currentZoom;
    const pdfViewerHeight = 1200 / currentZoom;

    const linhaDoVencimento = calcData[calcData.length - 1]
    const linhaPrimeira = calcData[0]
    const dataPrimeira = linhaPrimeira[1] < 10 ? `0${linhaPrimeira[1]}/${linhaPrimeira[2]}/${linhaPrimeira[3]}` : `${linhaPrimeira[1]}/${linhaPrimeira[2] - 1}/${linhaPrimeira[3]}`;
    const dataDoVencimento = linhaDoVencimento[1] < 10 ? `0${linhaDoVencimento[1]}/${linhaDoVencimento[2]}/${linhaDoVencimento[3]}` : `${linhaDoVencimento[1]}/${linhaDoVencimento[2]}/${linhaDoVencimento[3]}`;
    const base = formValues.dias === 30 ? 360 : 252;
    const dataHoje = `${now.getDate()}/${now.getMonth() + 1}/${now.getFullYear()}`;
    const styles = pdfStyle
    const avalistas = emissor.avalistas || [];
    console.log(avalistas, 'avalistas');
    const dataNowFormataded = formatDate(now.toISOString().split('T')[0]);

    const _dataExpiraFormataded = new Date(`${linhaDoVencimento[2]}-${linhaDoVencimento[1]}-${linhaDoVencimento[3]}`);
    const dataExpiraFormataded = formatDate(_dataExpiraFormataded.toISOString().split('T')[0]);

    const valorC3 = parseFloat(formValues.taxaEmissao) + parseFloat(formValues.assinaturaDigital) + parseFloat(formValues.tac) + parseFloat(formValues.alienacao);
    const garantiaFidejussoria = (avalistas[0]?.aNome?.length)>0 ?? false;

    return (
        <PDFViewer width={'90%'} height={'85%'}>
            <Document>
                <Page style={styles.page}>
                    <View style={styles.textHeader}>
                        <Text style={[styles.textHeader]}>TERMO CONSTITUTIVO DE NOTA COMERCIAL DA {dados.emissao} EMISSÃO DE NOTAS COMERCIAIS, EM SÉRIE ÚNICA, PARA COLOCAÇÃO
                            PRIVADA, {emissor.razaoSocial}</Text>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.h1}>I. EMISSORA</Text>
                        <View style={styles.tableContainer}>
                            <View style={styles.row}>
                                <Text style={styles.cellRight}><Text style={styles.headerCell}>Razão Social</Text> {'\n'}{emissor.razaoSocial}</Text>
                                <Text style={styles.cell}><Text style={styles.headerCell}>CNPJ/ME</Text>{'\n'}{emissor.cnpj}</Text>
                            </View>
                        </View>
                        <Text style={[styles.text3,styles.justify]}>
                            A Emissão e a celebração deste Termo Constitutivo de Nota Comercial da {dados.emissao} Emissão de Notas
                            Comerciais, em Série Única, para Colocação Privada, da {emissor.razaoSocial} (“<Text style={styles.textLine}> Termo Constitutivo</Text>”) são realizadas com base nas deliberações dos administradores da Emissora, conforme 
                            previsto no Parágrafo único, artigo 46 da Lei nº14.195, de 26 de agosto de 2021 (“<Text style={styles.textLine}> Lei 14.195 </Text>”) e no contrato social da Emissora.
                        </Text>
                        <Text style={styles.h1}>II. RESUMO DA EMISSÃO</Text>
                        <View style={styles.tableContainer}>
                            <View style={styles.row}>
                                <Text style={styles.cell2}>
                                    <Text style={styles.textBold}>Número da Emissão</Text>{'\n'}
                                    {dados.emissao}
                                </Text>
                                <Text style={styles.cell2}>
                                    <Text style={styles.textBold}>Série {'\n'}
                                    </Text> Única 
                                </Text>
                                <Text style={styles.cell2}>
                                    <Text style={styles.textBold}>Local de Emissão</Text> {'\n'}
                                    {emissor.cidade} - {emissor.estado}
                                </Text>
                                <Text style={[styles.cell2, { borderRightWidth: 0 }]}>
                                    <Text style={styles.textBold}>Data de Emissão</Text>{'\n'}
                                    {dataNowFormataded}
                                </Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.cell2}>
                                    <Text style={styles.textBold}>Data de Vencimento</Text>{'\n'}
                                    {dataExpiraFormataded}
                                </Text>
                                <Text style={styles.cell2}><Text style={styles.textBold}>Valor Total
                                    da Emissão</Text> {valorTotal}</Text>
                                <Text style={styles.cell2}><Text style={styles.textBold}>Valor Nominal Unitário </Text>
                                    {valorTotal}
                                </Text>
                                <Text style={[styles.cell2, { borderRightWidth: 0 }]}><Text style={styles.textBold}>Quantidade</Text>{'\n'} 
                                    1</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.cell2}>
                                    <Text style={styles.textBold}>Conversibilidade</Text>{'\n'}
                                    Simples, ou seja, não conversíveis em participação societária da Emissora.
                                </Text>
                                <Text style={styles.cell2}><Text style={styles.textBold}>Custo da Emissão
                                    da Emissão</Text>{'\n'}
                                    {valorC3.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} a ser pago pela(o) {emissor.razaoSocial} à responsável pela excrituração da nota comercial(conforme abaixo definida), na conta corrente abaixo indicada, na data da liquidação das Notas Comerciais. 
                                    <Text>{'\n'}{'\n'}
                                    Banco: Inter (077){'\n'}
                                    Agência: 0001{'\n'}
                                    Conta Corrente: 23203346-3{'\n'}
                                    </Text>
                                </Text>
                                <Text style={styles.cell2}><Text style={styles.textBold}>
                                    Garantias
                                </Text>{'\n'}
                                    Notas Comerciais não contarão com quaisquer garantias, sejam reais ou fidejussórias.
                                </Text>
                                <Text style={[styles.cell2, { borderRightWidth: 0 }]}><Text style={styles.textBold}>
                                Moeda da Emissão
                                </Text>{'\n'}
                                    Reais (R$)
                                </Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.cell2}><Text style={styles.textBold}>
                                    Repactuação
                                </Text>{'\n'}
                                    As Notas Comerciais não serão objeto de repactuação programada.
                                </Text>
                                <Text style={styles.cell2}><Text style={styles.textBold}>Classificação de 
                                    Risco </Text>{'\n'}
                                    Não será contratada agência de classificação de risco da Emissora ou das Notas Comerciais no âmbito da Oferta Restrita.
                                </Text>
                                <Text style={[styles.cell2, {
                                    width:"100%",
                                    borderRightWidth:"0px"
                                    }]}><Text style={styles.textBold}>
                                    Tipo de Oferta 
                                </Text>{'\n'} 
                                    Privada, isto é: não será realizada oferta pública de valores mobiliários para fins de colocação das Notas Comerciais

                                </Text>
                            </View>
                            {/* <View style={styles.row}>
                                <Text style={[styles.cell5, { textAlign: 'start', width: '100%' }]}><Text style={styles.textBold}>Juros Remuneratórios</Text>{'\n'}
                                    {allInfoArray[10]}% a.m., base {base} ({numero.porExtenso(base, numero.estilo.normal)}) dias.</Text>
                            </View> */}
                            <View style={styles.row}>
                                <Text style={styles.cell2}><Text style={styles.textBold}>Local de Pagamento</Text>{'\n'}
                                    São Paulo – SP, em conta
                                    corrente do Titular</Text>
                                <Text style={styles.cell2}><Text style={styles.textBold}>Atualização
                                    Monetária</Text>{'\n'}As Notas
                                    Comerciais não
                                    serão atualizadas
                                    monetariamente.</Text>
                                <Text style={styles.cell2}><Text style={styles.textBold}>Juros Remuneratórios</Text>{'\n'}
                                    {allInfoArray[10]}% a.m. base 360 (trezentos e sessenta) dias.

                                </Text>
                                <Text style={[styles.cell2, { borderRightWidth: 0 }]}><Text style={styles.textBold}>Datas de Pagamento dos
                                    Juros Remuneratórios</Text>{'\n'}<Text style={styles.textBold}>      </Text>Conforme Fluxo de
                                    pagamento do Anexo 2</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.cellRight}><Text style={styles.textBold}>Data de Pagamento do
                                    Valor Nominal Unitário</Text>{'\n'}
                                    Na data de vencimento.
                                </Text>

                                <Text style={styles.cellRight}><Text style={styles.textBold}>Amortização Extraordinária</Text>{'\n'}
                                    Haverá possibilidade de
                                    amortização extraordinária,
                                    seja total ou parcial, das
                                    Notas Comerciais, desde que
                                    amortizados também os juros
                                    incorridos no respectivo
                                    período.
                                </Text>
                                <Text style={styles.cellRight}><Text style={styles.textBold}>
                                    Resgate
                                    Antecipado
                                    Facultativo Total e
                                    Oferta de Resgate
                                    Antecipado</Text>{'\n'}Mediante o
                                    pagamento dos
                                    juros incorridos no
                                    respectivo
                                    periodo.
                                </Text>
                                <Text style={[styles.cellRight, { borderRightWidth: 0 }]}><Text style={styles.textBold}>Resgate Antecipado
                                    Compulsório Total</Text>{'\n'}As Notas Comerciais
                                    deverão ser resgatadas
                                    integralmente caso
                                    seja decretado o
                                    Vencimento
                                    Antecipado das Notas
                                    Comerciais, conforme
                                    abaixo definido.
                                </Text>
                            </View>
                        </View>
                    </View>
                </Page>
                <Page style={[styles.page,styles.justify]}>
                    <Text style={styles.h1}>III. PARTICIPANTES</Text>
                    <View style={styles.tableContainer}>
                        <View style={styles.row}>
                            <Text style={styles.cell2}>
                                <Text style={styles.textBold}>
                                Escriturador
                                </Text> {'\n'}
                                Laqus Depositária de Valores Mobiliários S.A.
                            </Text>
                            <Text style={[styles.cell,{borderBottomWidth: '1.5px',borderBottomColor: 'black'}]}><Text style={styles.textBold}>CNPJ/MF</Text>
                                    {'\n'}33.268.302/0001-02
                            </Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={[styles.cellRight,{ paddingLeft: 10,}]}>
                                <Text style={styles.textBold}>
                                    Depositária de Valores Mobiliários
                                </Text> {'\n'}
                                Laqus Depositária de Valores Mobiliários S.A.
                                </Text>
                            <Text style={styles.cell}>
                                <Text style={styles.textBold}>
                                    CNPJ/MF
                                </Text>
                                    {'\n'}33.268.302/0001-02
                            </Text>
                        </View>
                    </View>

                    <Text style={styles.h1}>VI. COMUNICAÇÕES</Text>
                    <View style={styles.tableContainer}>
                        <View style={styles.row}>
                            <Text style={[styles.cell2,{
                                width:"50%",
                                textAlign:"left",
                                }]}>
                                <Text style={styles.textBold}>
                                Se para a Emissora
                                </Text> {'\n'}
                                Rua {emissor.rua}, nº{emissor.numero} {emissor?.complemento?`, ${emissor?.complemento}`:""} {'\n'}
                                Bairro {emissor.bairro}, Cidade de {emissor.cidade}, Estado do {estadoPorExtenso(emissor.estado)}, CEP {emissor.cep} {'\n'}
                            </Text>
                            <Text style={[styles.cell,{
                                borderBottomWidth: '1.5px',
                                borderBottomColor: 'black', 
                                width:"50%",
                                textAlign:"left",
                                }]}>
                                Att. Sr.(a): {emissor?.representante?`${emissor.representante}`:""}{'\n'}
                                {/* Telefone: [--] {'\n'} */}
                                E-mail: {emissor?.email?`${emissor.email}`:""} {'\n'}
                            </Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={[styles.cell2,{
                                width:"50%",
                                textAlign:"left",
                                }]}>
                                <Text style={styles.textBold}>
                                Se para o Escriturador
                                </Text> {'\n'}
                                Avenida Pedroso de Morais, nº 433, conjunto 52,{'\n'}
                                Cidade de São Paulo, Estado de São Paulo, CEP 05419-902 {'\n'} {'\n'}
                            </Text>
                            <Text style={[styles.cell,{
                                borderBottomWidth: '1.5px',
                                borderBottomColor: 'black', 
                                width:"50%",
                                textAlign:"left",
                                }]}>
                                Att. Srs: Luiz Carlos Belinello  {'\n'}
                                Telefone: (11) 3522 4022  {'\n'}
                                E-mail: escrituracao@laqus.com.br {'\n'}
                            </Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={[styles.cell2,{
                                width:"50%",
                                textAlign:"left",
                                }]}>
                                <Text style={styles.textBold}>
                                Se para a Depositária de Valores Mobiliários
                                </Text> {'\n'}
                                Avenida Pedroso de Morais, nº 433, conjunto 52,{'\n'}
                                Cidade de São Paulo, Estado de São Paulo, CEP 05419-902 {'\n'} {'\n'}
                            </Text>
                            <Text style={[
                                styles.cell,
                                {borderBottomWidth: '1.5px',
                                borderBottomColor: 'black', 
                                width:"50%",
                                textAlign:"left",
                                }]}>
                                Att. Srs: Luiz Carlos Belinello  {'\n'}
                                Telefone: (11) 3522 4022  {'\n'}
                                E-mail: escrituracao@laqus.com.br {'\n'}
                            </Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.cell5}>
                                As notificações, instruções e comunicações serão consideradas entregues quando recebidas sob protocolo ou com “aviso de recebimento” expedido pela Empresa Brasileira de Correios, ou por telegrama nos endereços acima. As comunicações enviadas por correio eletrônico serão consideradas recebidas na data de seu envio, desde que seu recebimento seja confirmado por meio de recibo emitido pelo remetente (recibo emitido pela máquina utilizada pelo remetente). A mudança de qualquer dos endereços acima deverá ser imediatamente comunicada às demais Partes pela Parte que tiver seu endereço alterado.
                                </Text>
                        </View>
                    </View>

                    <Text style={styles.h1}>1. OBJETO</Text>
                    <View>  
                        <Text style={[styles.text1,styles.justify]}>O presente Termo Constitutivo tem por objeto a {dados.emissao} emissão de notas comerciais, não conversíveis, em série única, para colocação privada, da Emissora (“<Text style={styles.textLineDefault}>Emissão</Text>” e “<Text style={styles.textLineDefault}>Notas Comerciais</Text>”, respectivamente), representativas de promessa de pagamento em dinheiro, de acordo com as características, termos e condições estabelecidos neste Termo Constitutivo, nos termos da Lei nº 6.385, de 7 de dezembro de 1976, conforme alterada e da Lei 14.195.
                        </Text>
                    </View>
                    <Text style={styles.h1}>2. CONDIÇÕES PRECEDENTES</Text>
                        <Text style={styles.text1}>A Emissão, está condicionada, nos termos do artigo 125 da Lei 10.406, de 10 de janeiro de 2002, conforme
                            alterada, à verificação do cumprimento dos seguintes atos (“<Text style={styles.textLineDefault}>Condições Precedentes</Text>”):
                        </Text>
                        <Text style={styles.text5}>
                            {'\n'}{'  '}<Text style={styles.textBold}>a.</Text>  perfeita formalização, pela Emissora e demais partes signatárias, do Termo Constitutivo e do Boletim 
                            de Subscrição (conjuntamente, os “<Text style={styles.textLineDefault}>Documentos da Operação</Text>”), bem como a verificação dos poderes dos
                            representantes dessas partes e eventuais aprovações societárias necessárias à celebração desses documentos;
                            {'\n'}{'  '}<Text style={styles.textBold}>b.</Text> recebimento, pela Emissora, dos Boletins de Subscrição devidamente formalizados, cujo modelo segue
                            anexo a este Termo Constituivo no <Text style={styles.textLineDefault}>Anexo I</Text>.
                    </Text>
                </Page>
                
                {/* <Page style={[styles.page,styles.justify]}>
                    <View style={[styles.section,styles.justify]}>

                        <Text style={styles.h1}>3 GARANTIAS</Text>

                        <Text style={styles.text1}>Garantia Fidejussória</Text>

                        {avalistas.map((avalista, index) => (
                            <Text key={index} style={styles.text1}>{'\n'}Aval.
                                Comparece {avalista.aNome && ` ${avalista.aNome},`} brasileiro, {avalista.aEstadoCivil && `${avalista.aEstadoCivil},`} {avalista.aProfissao && `${avalista.aProfissao},`} nascido em {avalista.aDataNascimento && `${nascimentoAvalista(avalista.aDataNascimento)},`} inscrito no CPF nº: {avalista.aCpf && ` ${avalista.aCpf}`} portador da Carteira Identidade n.º {avalista.aRg && ` ${avalista.aRg}`} {avalista.aOrgaoEmissor && ` ${avalista.aOrgaoEmissor}`}residente e domiciliado na {avalista.aRua && ` ${avalista.aRua},`} {avalista.aNumero && ` ${avalista.aNumero},`} {avalista.aBairro && ` ${avalista.aBairro} –`} {avalista.aEstado && ` ${avalista.aEstado},`} CEP {avalista.aCep && ` ${avalista.aCep},`} (“Avalista”) no presente Termo Constitutivo, em caráter irrevogável e irretratável, na condição de avalista, principais pagadores e responsáveis solidários com relação às Obrigações Garantidas.
                            </Text>
                        ))}

                        <Text style={styles.text1}>
                            {'\n'}
                            O Avalista, na condição de devedor solidário e principal pagador, juntamente com a Emissora, perante o Titular, para o adimplemento das Obrigações Garantidas, assinam o presente Termo Constitutivo e declaram estarem cientes da presente garantia, aceitando todos os termos, condições e responsabilidades que daí advenham, sem a existência de qualquer benefício de ordem entre a Emissora e o Avalista.
                        </Text>
                        <Text style={styles.text1}>
                            {'\n'}
                            O presente Aval entrará em vigor na Data de Emissão e permanecerá válido enquanto existirem Obrigações Garantidas, extinguindo-se imediata e automaticamente mediante seu integral cumprimento.
                        </Text>
                        <Text style={styles.text1}>
                            {'\n'}
                            O Aval aqui previsto considera-se prestado a título oneroso, de forma que o Avalista possui interesse econômico no resultado da operação, beneficiando-se indiretamente da mesma.
                        </Text>
                        <Text style={styles.text1}>
                            {'\n'}
                            Uma vez declarado o vencimento antecipado das Notas Comerciais, cabe ao Titular requerer a execução judicial ou extrajudicial, do Aval. O Aval poderá ser excutido e exigido pela mesma quantas vezes forem necessárias para o integral pagamento das obrigações garantidas pelo Aval contra o Avalista.
                        </Text>
                        <Text style={styles.text1}>
                            {'\n'}
                            O Avalista deverá pagar as Obrigações Garantidas em até 2 (dois) Dias Úteis contados da data do respectivo inadimplemento da Emissora.
                        </Text>
                        <Text style={styles.text1}>
                            {'\n'}
                            Fica desde já certo e ajustado que a inobservância, pelo Titular, dos prazos para execução do Aval não ensejará, sob hipótese nenhuma, perda de qualquer direito ou faculdade aqui previsto, podendo o Aval ser excutido e exigido pelo Titular, judicial ou extrajudicialmente, quantas vezes forem necessárias até a integral liquidação das Obrigações Garantidas.
                        </Text>
                        <Text style={styles.text1}>
                            {'\n'}
                            Os pagamentos que vierem a ser realizados pelo Avalista com relação às Notas Comerciais serão realizados de modo que o Titular receba do Avalista os valores que lhes seriam entregues caso esses pagamentos tivessem sido realizados pela Emissora, não cabendo ao Avalista realizar qualquer dedução que não seria realizada pela Emissora, caso a Emissora tivesse realizado o respectivo pagamento.
                            {'\n'}
                        </Text>
                    </View>
                </Page> */}

                <Page style={[styles.page,styles.justify]}>
                    <View style={styles.section}>
                        <Text style={[styles.h1, { marginTop: 40 }]}>3. VENCIMENTO ANTECIPADO</Text>
                        <Text style={styles.text5}>
                            {'\n'}{'  '}Observado o disposto a seguir, o titular das Notas Comerciais poderá declarar antecipadamente vencidas todas as obrigações constantes deste Termo Constitutivo (“Vencimento Antecipado”) na ocorrência de qualquer uma das hipóteses abaixo:
                        </Text>
                        <Text style={styles.text5}>
                            {'\n'}{'  '}<Text style={styles.textBold}>a.        </Text> Se ocorrer inadimplemento de qualquer obrigação assumida pela Emissora em consonância com as cláusulas e condições aqui estabelecidas, principalmente no que tange ao pagamento das parcelas devidas em decorrências da celebração deste Termo Constitutivo e emissão das Notas Comerciais;
                            {'\n'}{'  '}<Text style={styles.textBold}>b.        </Text> Se for comprovada a falsidade de qualquer declaração, informação ou documento que houver sido respectivamente firmada, prestada ou entregue pela Emissora;
                            {'\n'}{'  '}<Text style={styles.textBold}>c.        </Text> Se a Emissora sofrer quaisquer medidas judiciais ou extrajudiciais, que por qualquer forma, possam afetar negativamente os créditos das Notas Comerciais; e
                            {'\n'}{'  '}<Text style={styles.textBold}>d.        </Text> Se, sem o expresso consentimento dos credores das Notas Comerciais ocorrer a transferência a terceiros dos direitos e obrigações da Emissora previstos neste Termo Constitutivo.
                            {'\n'}{'  '}<Text style={styles.textBold}>e.        </Text> Se ocorrer a transferência a terceiros dos direitos e obrigações da Emissora, previstos neste Termo Constitutivo.
                        </Text>
                        <Text style={[styles.h1, { marginTop: 40 }]}>4. DEPÓSITO CENTRALIZADO</Text>
                        <Text style={styles.text5}>
                            {'\n'}{'  '}As Notas Comerciais serão depositadas perante a Laqus Depositária de Valores Mobiliários S.A., inscrita no CNPJ/MF sob o nº 33.268.302/0001-02 (“Depositária”), que fará o depósito centralizado e custódia eletrônica das Notas Comerciais, com as atribuições indicadas pelo artigo 49 da Lei 14.195/21, sendo admissível, no entanto, a substituição ou interoperabilidade das Notas Comerciais para outra central depositária de valores mobiliários,  desde que  a instituição esteja devidamente autorizada pela CVM, nos termos da Resolução CVM nº 31.
                        </Text>
                        <Text style={styles.text5}>
                            {'\n'}{'  '}A solicitação será realizada pelo Titular em relação às suas quantidades de Notas Comerciais, observando, para tanto, as regras previstas pelo Manual de Interoperabilidade da Laqus, na qualidade de Depositária de Origem, bem como os procedimentos indicados pela central depositária de destino. Em razão do depósito, as Notas Comerciais estão habilitadas para distribuição em oferta primária, bem como negociação no mercado secundário, conforme o caso.
                        </Text>
                        <Text style={styles.text5}>
                            {'\n'}{'  '}A Emissora declara estar ciente e de acordo com todos os termos, procedimentos e condições do Regulamento de Acesso e Operações e demais Regulamentos e Manuais Operacionais da Depositária, disponíveis em seu website (https://www.laqus.com.br/documentos), a eles aderindo formalmente, em caráter irrevogável e irretratável, obrigando-se a respeitá-los e a cumpri-los, fielmente, comprometendo-se com todos os termos, condições e direitos neles previstos e, inclusive, em suas eventuais alterações e aditamentos, complementos ou novas versões. 
                        </Text>
                        <Text style={styles.text5}>
                            {'\n'}{'  '}Será concedido à Emissora o direito de acesso à Plataforma IMF Digital, na qualidade de Participante, mediante a criação de usuários master a serem disponibilizados aos seus representantes legais, bem como aos demais usuários indicados no momento da solicitação de cadastro, em consonância aos procedimentos e normativos vigentes à época.
                        </Text>
                        <Text style={[styles.h1, { marginTop: 40 }]}>5. ESCRITURADOR</Text>
                        <Text style={styles.text5}>
                            {'\n'}{'  '}As Notas Comerciais Escriturais serão emitidas sob a forma exclusivamente escritural, nos termos do artigo 45 da Lei nº 14.195. O escriturador da presente Emissão é a Laqus Depositária de Valores Mobiliários S.A., com sede na Avenida Pedroso de Morais, nº 433, conjunto 52, Cidade de São Paulo, Estado de São Paulo, CEP 05419-902, inscrita no CNPJ/ME sob o n° 33.268.302/0001-02 (“Escriturador”). O Escriturador será responsável por realizar a escrituração das Notas Comerciais Escriturais, entre outras responsabilidades definidas nas normas editadas pela CVM. Desde já o emissor declara estar ciente e de acordo com todos os termos, procedimentos e condições do Contrato de Escrituração e demais Manuais, disponíveis em seu website (https://www.laqus.com.br/documentos), a eles aderindo formalmente, em caráter irrevogável e irretratável, obrigando-se a respeitá-los e a cumpri-los, fielmente, comprometendo-se com todos os termos, condições e direitos neles previstos e, inclusive, em suas eventuais alterações e aditamentos, complementos ou novas versões.  
                            
                        </Text>
                        <Text style={[styles.h1, { marginTop: 40 }]}>6. DIREITO AO RECEBIMENTO DOS PAGAMENTOS</Text>
                        <Text style={styles.text5}>
                            {'\n'}{'  '}Terá direito ao recebimento dos valores devidos em relação às Notas Comerciais, seja o pagamento realizado em caráter ordinário ou extraordinário, os Titulares das Notas Comerciais constituídos no encerramento do dia útil imediatamente anterior à respectiva data de pagamento.
                        </Text>
                        <Text style={[styles.h1, { marginTop: 40 }]}>7. DAS DECLARAÇÕES</Text>
                        <Text style={styles.text5}>A Emissora declara e garante que:
                            {'\n'}
                            {'\n'}{'  '}<Text style={styles.textBold}>a.        </Text> Possui plena capacidade e legitimidade para celebrar o presente Termo Constitutivo, realizar todas as operações e cumprir todas as obrigações assumidas tendo tomado todas as medidas de natureza societária, conforme aplicável, e outras eventualmente necessárias para autorizar a sua celebração, implementação e cumprimento de todas as obrigações constituídas;
                            {'\n'}{'  '}<Text style={styles.textBold}>b.       </Text> A celebração deste Termo Constitutivo e o cumprimento das obrigações nele dispostos: (a) não violam qualquer disposição contida nos seus documentos societários, conforme aplicável; (b) não violam qualquer lei, regulamento, decisão judicial, administrativa ou arbitral, aos quais a respectiva parte esteja vinculada; (c) não exigem qualquer consentimento ação ou autorização, prévia ou posterior, de terceiros;
                            {'\n'}{'  '}<Text style={styles.textBold}>c.        </Text> Este Termo Constitutivo é validamente celebrado e constitui obrigação legal, válida, vinculante e exequível, de acordo com os seus termos;
                            {'\n'}{'  '}<Text style={styles.textBold}>d.        </Text> Está apta a cumprir as obrigações ora previstas neste Termo Constitutivo e agirá em relação às mesmas de boa-fé e com lealdade;
                            {'\n'}{'  '}<Text style={styles.textBold}>e.        </Text> Não se encontra em estado de necessidade ou sob coação para celebrar este Termo Constitutivo e/ou quaisquer contratos e compromissos a ela relacionados e acessórios; e,
                            {'\n'}{'  '}<Text style={styles.textBold}>f.        </Text>   É devidamente estruturada, qualificada e capacitada para entender a estrutura financeira e jurídica objeto deste Termo Constitutivo.

                        </Text>
                        <Text style={[styles.h1, { marginTop: 40 }]}>8. DO ATRASO NO PAGAMENTO E ENCARGOS MORATÓRIOS</Text>
                        <Text style={styles.text5}>
                            Na hipótese de inadimplemento ou mora dos valores devidos no âmbito deste Termo Constitutivo, a Emissora estará obrigada a pagar ao titular, cumulativamente, além da quantia correspondente à dívida em aberto, os seguintes encargos:
                            {'\n'}{'  '}<Text style={styles.textBold}>a.        </Text> Juros remuneratórios nos mesmos percentuais das taxas constantes neste Termo Constitutivo, calculados a partir do vencimento das parcelas em aberto até a data do efetivo pagamento;
                            {'\n'}
                        </Text>
                        <Text style={styles.text5}>
                            <Text style={styles.textBold}>b.        </Text> Juros de mora à razão de 1% a.m. (um por cento ao mês), calculados a partir do vencimento das parcelas em aberto até a data do efetivo pagamento pela Emissora;
                            {'\n'}{'  '}<Text style={styles.textBold}>c.        </Text> Multa contratual, de natureza não compensatória, de 2% (dois por cento) incidente sobre o montante total atualizado (incluindo juros remuneratórios e juros de mora) do valor devido e não pago; e
                            {'\n'}{'  '}<Text style={styles.textBold}>d.        </Text> Na hipótese do Titular vir a ser compelido a recorrer a meios administrativos ou judiciais para receber os valores devidos no âmbito deste Termo Constitutivo, as despesas de cobrança administrativa, limitadas a 10% (dez por cento) sobre o valor do saldo devedor deste Termo Constitutivo e, havendo procedimento judicial, custas processuais e honorários advocatícios, estes fixados judicialmente.
                            {'\n'}{'  '}<Text style={styles.textBold}>e.        </Text> Caso qualquer data de vencimento referente aos pagamentos da Nota Comercial caia em um fim de semana ou feriado nacional, conforme tabela Anbima, o prazo para liquidação será prorrogado automaticamente para o primeiro dia útil subsequente, sem que incida sobre os valores a pagar quaisquer acréscimos de caráter remuneratório ou moratório.
                        </Text>
                    </View>
                </Page>
                <Page style={[styles.page,styles.justify]}>
                    <View style={styles.section}>
                        <Text style={styles.h1}>9. DISPOSIÇÕES GERAIS</Text>
                        <Text style={styles.textLine}>Renúncia
                        </Text>
                        <Text style={styles.text1}>
                            Não se presume a renúncia a qualquer dos direitos decorrentes das Notas Comerciais. Desta forma, nenhum atraso, omissão ou liberalidade no exercício de qualquer direito, faculdade ou prerrogativa que caiba ao Titular, em razão de qualquer inadimplemento da Emissora, prejudicará o exercício de tais direitos, faculdades ou remédios, ou será interpretado como constituindo uma renúncia aos mesmos ou concordância com tal inadimplemento, nem constituirá novação ou modificação de quaisquer outras obrigações assumidas pela Emissora neste instrumento, ou precedente no tocante a qualquer outro inadimplemento ou atraso.
                        </Text>
                        <Text style={styles.textLine}>Título Executivo Extrajudicial e Execução Específica
                        </Text>
                        <Text style={styles.text1}>
                            Este instrumento e as Notas Comerciais constituem títulos executivos extrajudiciais, nos termos dos incisos I e III do artigo 784 do Código de Processo Civil, reconhecendo as Partes desde já que, independentemente de quaisquer outras medidas cabíveis, as obrigações assumidas nos termos deste instrumento e com relação às Notas Comerciais estão sujeitas à execução específica, submetendo-se às disposições dos artigos 815 e seguintes do Código de Processo Civil, sem prejuízo do direito de declarar o vencimento antecipado das Notas Comerciais, nos termos deste instrumento.
                        </Text>
                        <Text style={styles.textLine}>Outorga de Poderes
                        </Text>
                        <Text style={styles.text1}>
                            A Emissora, neste ato, em caráter irrevogável e irretratável, autoriza a central depositária de valores mobiliários a cadastrar o presente Termo Constitutivo na Plataforma IMF Digital, para fins de cumprimento do cadastro de instrumento financeiro, bem como criar eventuais usuários, se e conforme solicitados de maneira formalizada, para acesso a citada plataforma, em consonância aos seus normativos, bem como representá-la perante os demais prestadores de serviço envolvidos na Emissão, inclusive contratá-los em seu nome, desde que a obrigatoriedade de pagamento seja do grupo Laqus.
                        </Text>
                        <Text style={styles.textLine}>Irrevogabilidade
                        </Text>
                        <Text style={styles.text1}>
                            As Notas Comerciais e o presente instrumento são celebrados em caráter irrevogável e irretratável, obrigando as partes e seus sucessores a qualquer título.
                        </Text>
                        <Text style={styles.textLine}>Cômputo do Prazo
                        </Text>
                        <Text style={styles.text1}>
                            Exceto se de outra forma especificamente disposto neste instrumento, os prazos estabelecidos no presente instrumento serão computados de acordo com a regra prescrita no artigo 132 do Código Civil, sendo excluído o dia do começo e incluído o do vencimento.
                        </Text>
                        <Text style={styles.textLine}>Assinatura Eletrônica
                        </Text>
                        <Text style={styles.text1}>
                            Para os fins do artigo 10, parágrafo 2º, da Medida Provisória nº 2.200-2, de 24 de agosto de 2001, as Partes acordam e aceitam que este instrumento e qualquer aditamento podem ser assinados eletronicamente por meio de plataforma de assinatura, com certificados digitais emitidos pela ICP-Brasil, e tais assinaturas eletrônicas serão legítimas e suficientes para comprovar (i) a identidade de cada representante legal, (ii) a vontade de cada Parte em firmar este instrumento e qualquer aditamento, e (iii) a integridade deste instrumento e qualquer alteração. As Partes acordam que independentemente da data e do local em que a assinatura eletrônica de qualquer dos signatários for realizada, a data e o local deste instrumento serão aqueles escolhidos pelas Partes ao final deste instrumento.
                        </Text>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.textLine}>Boa-fé e Equidade
                        </Text>
                        <Text style={styles.text1}>
                            As Partes declaram, mútua e expressamente, que este instrumento foi celebrado respeitando-se os princípios de probidade e de boa-fé, por livre, consciente e firme manifestação de vontade das Partes e em perfeita relação de equidade.
                        </Text>
                        <Text style={styles.textLine}>Lei Aplicável
                        </Text>
                        <Text style={styles.text1}>
                            Este instrumento é regido pelas Leis da República Federativa do Brasil.
                        </Text>
                        <Text style={styles.textLine}>Foro
                        </Text>
                        <Text style={styles.text1}>
                            Fica eleito o foro da Comarca da Cidade de {emissor.cidade}, Estado de {estadoPorExtenso(emissor.estado)}, para dirimir quaisquer dúvidas ou controvérsias oriundas deste instrumento, com renúncia a qualquer outro, por mais privilegiado que seja.
                        </Text>
                    </View>
                </Page>
                <Page style={styles.page}>
                    <View style={styles.section}>
                        <Text style={[styles.centerH1, { marginBottom: 20, paddingHorizontal: 20 }]}>
                            PÁGINA DE ASSINATURAS DO TERMO CONSTITUTIVO DE NOTA COMERCIAL DA {dados.emissao} EMISSÃO DE NOTAS COMERCIAIS, EM SÉRIE ÚNICA, PARA COLOCAÇÃO PRIVADA, DO(A) {emissor.razaoSocial}.
                        </Text>                        
                        <Text style={[styles.text2, { textAlign: 'center', width: '50%', alignSelf: 'center', marginBottom: 100, marginTop:50 }]}>
                                {emissor.razaoSocial}{'\n'}
                                Na qualidade de Emissora
                        </Text>
                    </View>
                </Page>
                <Page style={styles.page}>
                    <View style={styles.section}>
                        <View style={styles.row}>
                            <Text style={[styles.cell3, { textAlign: 'center' }, styles.textBold]}>
                                BOLETIM DE SUBSCRIÇÃO DA {dados.emissao} EMISSÃO DE NOTAS COMERCIAIS, EM SÉRIE ÚNICA, PARA

                                COLOCAÇÃO PRIVADA, DA {emissor.razaoSocial}.
                            </Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={[styles.cell3, { textAlign: 'center' }, styles.textBold]}>
                                CARACTERÍSTICAS DA EMISSÃO
                            </Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.cell3}>
                                Emissão, pela {emissor.razaoSocial} pessoa jurídica situada na {emissor.rua}, Nº {emissor.numero}, {emissor.bairro} - {emissor.cidade}/{emissor.estado} – CEP: {emissor.cep} inscrita no CNPJ/MF sob o nº {emissor.cnpj} ("Companhia"), de 1 notas
                                comerciais, com valor nominal unitário de {valorTotal} e vencimento em {dataDoVencimento}, da sua {dados.emissao} emissão ("Notas Comerciais" e "Emissão").
                            </Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={[styles.cell3, { textAlign: 'center' }, styles.textBold]}>
                                QUALIFICAÇÃO DO SUBSCRITOR</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.cell3}>
                                Nome / Razão Social: {investidor.razaoSocial}</Text>
                            <Text style={styles.cell3}>
                                CNPJ /MF: {investidor.cnpj}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.cell3}>
                                Endereço: {investidor.rua}</Text>
                            <Text style={styles.cell3}>
                                Nº: {investidor.numero}</Text>
                            <Text style={styles.cell3}>
                                Complemento:{investidor.complemento}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.cell3}>
                                Bairro: {investidor.bairro}</Text>
                            <Text style={styles.cell3}>
                                CEP: {investidor.cep}</Text>
                            <Text style={styles.cell3}>
                                Cidade: {investidor.cidade}</Text>
                            <Text style={styles.cell3}>
                                U.F.: {investidor.estado}</Text>
                            <Text style={styles.cell3}>
                                País: {'Brasil'}</Text>
                            <Text style={styles.cell3}>
                                Telefone: {investidor.telefone}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={[styles.cell3, { textAlign: 'center' }, styles.textBold]}>
                                NOTAS COMERCIAIS SUBSCRITAS</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={[styles.cell3, styles.textBold, { borderRightWidth: 0 }]}>
                                QUANTIDADE</Text>
                            <Text style={[styles.cell3, styles.textBold]}>
                                PREÇO TOTAL UNITÁRIO</Text>
                            <Text style={[styles.cell3, styles.textBold]}>
                                VALOR EM R$:</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.cell3}>
                                1</Text>
                            <Text style={styles.cell3}>
                                {allInfoArray[13]}</Text>
                            <Text style={styles.cell3}>
                                {allInfoArray[13]}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={[styles.cell3, { textAlign: 'center' }]}>O Preço Total Unitário será mantido em cada data de integralização, caso distintas.. A

                                rentabilidade apenas se inicia após a Data de Integralização.</Text>
                        </View>
                        <Text style={[styles.centerH1, { marginTop: 10, marginBottom: 10 }]}>FORMA SUBSCRIÇÃO E INTEGRALIZAÇÃO</Text>
                        <View style={styles.row}>
                            <Text style={[styles.cell3, styles.textBold]}>MEIO DE INTEGRALIZAÇÃO:{'\n'}{'\n'}{`${emissor.meioPagamento}`}</Text>
                            <Text style={[styles.cell3, { textAlign: 'center' }]}><Text style={styles.textBold}>DATA DE INTEGRALIZAÇÃO:</Text>{'\n'}{'\n'}A efetiva integralização deverá ser realizada
                                em até 30 (trinta) dias corridos, contados da
                                data de assinatura do presente Boletim de

                                Subscrição.</Text>

                        </View>

                        <Text style={[styles.centerH1, { marginTop: 10, marginBottom: 10 }]}>CONTA BANCÁRIA DA EMISSORA</Text>
                        <View style={styles.row}>
                            <Text style={[styles.cell3, { backgroundColor: 'grey' }, styles.textBold]}>Banco</Text>
                            <Text style={[styles.cell3, { backgroundColor: 'grey' }, styles.textBold]}>Nº Do Banco</Text>
                            <Text style={[styles.cell3, { backgroundColor: 'grey' }, styles.textBold]}>Nº da Agência</Text>
                            <Text style={[styles.cell3, { backgroundColor: 'grey' }, styles.textBold]}>Nº da Conta</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.cell3}>{emissor.banco}</Text>
                            <Text style={styles.cell3}>{emissor.bankNumber}</Text>
                            <Text style={styles.cell3}>{emissor.agency}</Text>
                            <Text style={styles.cell3}>{emissor.conta}</Text>
                        </View>
                        {/* <Text style={[styles.centerH1, { marginTop: 10, marginBottom: 10 }]}>CONTA BANCÁRIA DO ESCRITURADOR</Text>
                        <View style={styles.row}>
                            <Text style={[styles.cell3, { backgroundColor: 'grey' }, styles.textBold]}>Banco</Text>
                            <Text style={[styles.cell3, { backgroundColor: 'grey' }, styles.textBold]}>Nº Do Banco</Text>
                            <Text style={[styles.cell3, { backgroundColor: 'grey' }, styles.textBold]}>Nº da Agência</Text>
                            <Text style={[styles.cell3, { backgroundColor: 'grey' }, styles.textBold]}>Nº da Conta</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.cell3}>Singulare</Text>
                            <Text style={styles.cell3}>363</Text>
                            <Text style={styles.cell3}>0001</Text>
                            <Text style={styles.cell3}>45-1</Text>
                        </View> */}
                        <Text style={[styles.centerH1, { marginTop: 10, marginBottom: 10 }]}>CONTA BANCÁRIA DO SUBSCRITOR</Text>
                        <View style={styles.row}>
                            <Text style={[styles.cell3, { backgroundColor: 'grey' }, styles.textBold]}>Banco</Text>
                            <Text style={[styles.cell3, { backgroundColor: 'grey' }, styles.textBold]}>Nº Do Banco</Text>
                            <Text style={[styles.cell3, { backgroundColor: 'grey' }, styles.textBold]}>Nº da Agência</Text>
                            <Text style={[styles.cell3, { backgroundColor: 'grey' }, styles.textBold]}>Nº da Conta</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.cell3}>{investidor.banco}</Text>
                            <Text style={styles.cell3}>{investidor.numeroDoBanco}</Text>
                            <Text style={styles.cell3}>{investidor.agencia}</Text>
                            <Text style={styles.cell3}>{investidor.conta}</Text>
                        </View>
                        <View style={styles.row}>
                            <View style={styles.cell3}>
                                <Text style={[{ fontSize: 12, paddingBottom: 10, }]}>
                                    Declaro, para todos os fins, que estou de acordo com as condições expressas no presente
                                    boletim, bem como declaro ter obtido exemplar do Termo Constitutivo.{'\n'}
                                </Text>
                                <Text style={[{ textAlign: 'center', paddingBottom: '20px', fontSize: 12, borderBottomWidth: 1, borderBottomColor: 'black' }]}>
                                    {`${investidor.cidade}, ${dataHoje}`}{'\n'}</Text>
                                <View style={[{ borderBottomWidth: 1, borderBottomColor: 'black', marginTop: 30, width: '50%', textAlign: 'center', alignSelf: 'center' }]}></View>
                                <Text style={[styles.text, { textAlign: 'center', }]}>Assinatura do Subscritor ou Representante Legal</Text></View>

                        </View>
                    </View>
                </Page>
                <Page style={styles.page}>
                    <View style={styles.section}>
                        <Text style={[styles.centerH1, { marginTop: 10, marginBottom: 10 }]}>ANEXO II - FLUXO DE PAGAMENTO</Text>
                        <View style={styles.downTable}>
                            <View style={styles.tr}>
                                <Text style={[styles.centralizadoTh, { borderRightWidth: '1px', borderRightColor: 'black', width: '70px', paddingTop:15,},]}>
                                    Parcela
                                </Text>
                                <Text style={[styles.centralizadoTh, { borderRightWidth: '1px', borderRightColor: 'black', width: '82px', paddingTop:5, paddingBottom:10 },]}>
                                    Data de {'\n'}Vencimento
                                </Text>
                                <Text style={[styles.centralizadoTh, { borderRightWidth: '1px', borderRightColor: 'black', width: '90px', paddingTop:15, },]}>
                                    Indexador
                                </Text>
                                <Text style={[styles.centralizadoTh, { borderRightWidth: '1px', borderRightColor: 'black', width: '88px', paddingTop:15, },]}>
                                    Juros
                                </Text>
                                <Text style={[styles.centralizadoTh, { borderRightWidth: '1px', borderRightColor: 'black', width: '91px', paddingTop:5, },]}>
                                    Valor {'\n'}
                                    Amortização
                                </Text>
                                <Text style={[styles.centralizadoTh, { borderRightWidth: '1px', borderRightColor: 'black', width: '90px', paddingTop:5, },]}>
                                    Valor Total {'\n'}
                                    de Parcela
                                </Text>
                                {/* <Text style={[styles.centralizadoTh, { borderRightWidth: '1px', borderRightColor: 'black', width: '100px', paddingTop:15, },]}>Saldo Devedor</Text> */}
                            </View>

                            <View style={styles.tr}>
                                <Text style={[styles.centralizado, { borderRightWidth: '1px', borderRightColor: 'black', width: '70px' }]}>
                                    0
                                </Text>
                                <Text style={[styles.centralizado, { borderRightWidth: '1px', borderRightColor: 'black', width: '82px' }]}>
                                    {now.getDate().toString().padStart(2, '0')}/{(now.getMonth() + 1).toString().padStart(2, '0')}/{now.getFullYear()}
                                </Text>
                                <Text style={[styles.alinhamentoDireitaTd, { width: '90px' }]}>
                                    0
                                </Text>
                                <Text style={[styles.alinhamentoDireitaTd, { width: '88px' }]}>
                                    0
                                </Text>
                                <Text style={[styles.alinhamentoDireitaTd, { width: '91px' }]}>
                                    0
                                </Text>
                                <Text style={[styles.alinhamentoDireitaTd, { width: '90px' }]}>
                                    0
                                </Text>
                                {/* <Text style={[styles.alinhamentoDireitaTd, { width: '100px' }]}>{allInfoArray[13]}</Text> */}
                            </View>

                            {calcData && calcData.map((data, index) => (
                                <View key={index} style={styles.tr}>
                                    <Text style={[styles.centralizado, { borderRightWidth: '1px', borderRightColor: 'black', width: '70px' }]}>
                                        {data[0]}
                                    </Text>
                                    <Text style={[styles.centralizado, { borderRightWidth: '1px', borderRightColor: 'black', width: '82px' }]}>
                                        {data[1] < 10 ? `0${data[1]}` : data[1]}/{data[2] === '00' ? 12 : data[2]}/{data[3]}
                                    </Text>
                                    <Text style={[styles.alinhamentoDireitaTd, { width: '90px' }]}>
                                        0
                                    </Text>
                                    <Text style={[styles.alinhamentoDireitaTd, { width: '88px' }]}>
                                        {_formatMoneyBr(data[6])}
                                    </Text>
                                    <Text style={[styles.alinhamentoDireitaTd, { width: '91px' }]}>
                                        {_formatMoneyBr(data[5])}
                                    </Text>
                                    <Text style={[styles.alinhamentoDireitaTd, { width: '90px' }]}>
                                        {_formatMoneyBr(data[4])}
                                    </Text>
                                    {/* <Text style={[styles.alinhamentoDireitaTd, { width: '100px' }]}>
                                        {_formatMoneyBr(data[7])}
                                    </Text> */}
                                </View>
                            ))}
                        </View>
                    </View>
                </Page>
            </Document>
        </PDFViewer >
    )
}
const _formatMoneyBr = (money=0) => {
    return money.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
}

export default LaqusSemGarantia;
