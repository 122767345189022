// App.js
import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './redux/rootReducer';
import Login from './pages/Login';
import Register from './pages/Register';
import Result from './pages/Result';
import Home from './pages/Home';
import Account from './pages/Account';
import Update from './pages/Update';
import EnviarArquivos from './components/EnviarArquivos';
import DocGen from './pages/DocGen';
import Investidor from './pages/Investidor';
import Emissor from './pages/Emissor';
import Inicio from './pages/Inicio';
import AddEmployee from './pages/AddEmployee';
import EmittedNotes from './pages/EmittedNotes';
import ErrorBoundary from './components/ErrorBoundary'; // Importe o ErrorBoundary
import ErrorPage from './pages/ErrorPage'; // Importe a página de erro
import Irregular from './components/Irregular';
import Billets from './pages/Billets';
import Garantias from './pages/Garantias';

function App() {
  document.title = 'Nota Comercial';
  const store = configureStore({
    reducer: rootReducer,
  });

  return (
    <Provider store={store}>
      <Router>
        <ErrorBoundary>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/home" element={<Home />} />
            <Route path='/result' element={<Result />} />
            <Route path='/account' element={<Account />} />
            <Route path='/profile' element={<Account />} />
            <Route path='/update' element={<Update />} />
            <Route path='/upload' element={<EnviarArquivos />} />
            <Route path='/docgen' element={<DocGen />} />
            <Route path='/Investidor' element={<Investidor />} />
            <Route path='/Emissor' element={<Emissor />} />
            <Route path='/Inicio' element={<Inicio />} />
            <Route path='/addEmployee' element={<AddEmployee />} />
            <Route path='/emitted' element={<EmittedNotes />} />
            <Route path='/error' element={<ErrorPage />} />
            <Route path='/irregular' element={<Irregular />} />
            <Route path='/billets' element={<Billets />} />
            <Route path='garantias' element={<Garantias />} />
          </Routes>
        </ErrorBoundary>
      </Router>
    </Provider>
  );
}

export default App;
