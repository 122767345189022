import React, { useEffect } from "react";
import { Page, Text, View, Document, PDFViewer } from "@react-pdf/renderer";
import { useSelector } from 'react-redux';
import { pdfStyle } from "./pdfStyles";


const formatDate = (inputDate) => {
    const months = [
        "janeiro", "fevereiro", "março", "abril", "maio", "junho",
        "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"
    ];

    const dateParts = inputDate.split("-");
    const year = dateParts[0];
    const month = months[Number(dateParts[1]) - 1];
    const day = Number(dateParts[2]);

    return `${day} de ${month} de ${year}`;
};
const VortxSemGarantia = ({ dados }) => {
    const calcData2 = useSelector((state) => state.calcData.calcData)
    console.log('====================================');
    console.log(calcData2, 'calcData2');
    console.log('====================================');
    const calcData1 = JSON.parse(localStorage.getItem('calcData'))
    console.log(calcData1, 'calcData1');
    const calcData = calcData2 ? calcData2 : calcData1

    //const allInfoArray = useSelector((state) => state.finalTotal.finalTotal);
    console.log(calcData, 'calcData');
    //const valorTotal = allInfoArray[6]
    //const lugarEData = `${dados.adress.localidade}, ${formatDate(dados.vencimentoDate)}`;
    const soData = `${formatDate(dados.vencimentoDate)}`
    //const now = new Date();
    //const parcelas = allInfoArray[4]
    //const dias = parcelas * 30;
    const emissao = `${dados.emissao}`;
    const razaoSocial = dados.razaoSocial
    const currentZoom = window.devicePixelRatio;
    console.log(currentZoom);

    // Calcula o tamanho do componente PDFViewer com base no zoom
    const pdfViewerWidth = 1500 / currentZoom;
    const pdfViewerHeight = 1000 / currentZoom;
    useEffect(() => {
        if (typeof (calcData) !== 'object') {
            window.alert('Cálculo não realizado!')
        }
    },);
    const styles = pdfStyle
    return (
        <PDFViewer width={'90%'} height={'85%'}>
            <Document>
                <Page style={styles.page}>
                    <View style={styles.header}>
                        <Text style={[styles.textHeader]}>TERMO DE EMISSÃO DA {emissao} EMISSÃO DE NOTAS COMERCIAIS, SEM GARANTIA REAL,
                            EM SÉRIE ÚNICA, PARA DISTRIBUIÇÃO PRIVADA COM ESFORÇOS RESTRITOS DA{" "}
                            {razaoSocial}</Text>
                    </View>
                    <View style={styles.header}>
                        <Text style={[styles.text1]}>entre</Text>
                    </View>
                    <View style={styles.header}>
                        <Text style={[styles.text1]}>{dados.dRazao} como emitente</Text>
                    </View>
                    <View style={styles.header}>
                        <Text style={[styles.text1]}>VORTX DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA
                            como Escriturador,</Text>
                    </View>
                    <View style={styles.header}>
                        <Text style={[styles.text1]}>{razaoSocial}{" "}
                            como Titular</Text>
                    </View>
                    <View style={styles.header}>
                        <Text style={[styles.text1, {marginTop: 240}]}>Datado de:</Text>
                    </View>
                    <View style={styles.header}>
                        <Text style={[styles.text1]}>{soData}</Text>
                    </View>
                </Page>
                <Page style={styles.page}>
                    <View style={styles.header}>
                    <Text style={[styles.text1]}>NOTA COMERCIAL</Text>
                    </View>
                    <View style={styles.section}>
                        <Text>{razaoSocial}</Text>
                    </View>
                    </Page>
            </Document>
        </PDFViewer >
    )
}

export default VortxSemGarantia;
